// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { entries as _entries } from "lodash";

/**
 * Returns an array of a given object's own enumerable string-keyed [key, value] pairs.
 *
 * @param obj The object to convert to entries
 * @returns An array of key-value pairs
 */
export function entries<const T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return _entries(obj) as [keyof T, T[keyof T]][];
}
