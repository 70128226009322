import type { IOrganizationId } from "@archetype/ids";
import { builderTrpc, organizationGlobalContext } from "@archetype/trpc-react";
import { NonIdealState } from "@archetype/ui";
import { useClerk, useUser } from "@clerk/nextjs";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import { useEffect } from "react";
import { AppLoader } from "../AppLoader";
import { getRouterOrganizationId, routeRequiresAuthentication } from "./utils";
export const AuthenticationProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const router = useRouter();
  const orgId = getRouterOrganizationId(router);

  // Not set as an effect as we don't need to wait for another render loop
  if (organizationGlobalContext.getOrganizationId() !== orgId) {
    organizationGlobalContext.setOrganizationId(orgId);
  }

  // Do not do anything on public pages
  if (!routeRequiresAuthentication(router)) {
    return children;
  }
  return <RouteWithAuthentication orgId={orgId} data-sentry-element="RouteWithAuthentication" data-sentry-component="AuthenticationProvider" data-sentry-source-file="AuthenticationProvider.tsx">{children}</RouteWithAuthentication>;
};
const RouteWithAuthentication: React.FC<PropsWithChildren<{
  orgId?: IOrganizationId;
}>> = ({
  children,
  orgId
}) => {
  const router = useRouter();
  const {
    isLoaded: isUserLoaded,
    isSignedIn: isUserSignedIn
  } = useUser();
  // Ensures clerk is fully loaded
  const {
    loaded
  } = useClerk();
  if (!isUserLoaded || !loaded) {
    return <AppLoader />;
  }
  if (!isUserSignedIn) {
    void router.push({
      pathname: "/sign-in/[[...index]]",
      query: {
        returnUrl: router.asPath
      }
    });
    return <AppLoader />;
  }
  if (orgId != null) {
    return <RouteWithOrganizationRegistration orgId={orgId}>{children}</RouteWithOrganizationRegistration>;
  }
  return children;
};

/**
 * Handle sign-in redirects and registration of the user with the org
 *
 * Also handles setting the active org before allowing content to be rendered
 */
const RouteWithOrganizationRegistration: React.FC<PropsWithChildren<{
  orgId: IOrganizationId;
}>> = ({
  children,
  orgId
}) => {
  const {
    setActive
  } = useClerk();
  const {
    mutateAsync: registerToOrganization,
    data: registrationResult,
    isPending,
    isError
  } = builderTrpc.users.maybeRegisterToOrganization.useMutation();
  useEffect(() => {
    if (isPending || isError || registrationResult != null) {
      return;
    }
    const registerUser = async (): Promise<void> => {
      await registerToOrganization({
        organizationId: orgId
      });
    };
    void registerUser();
  }, [registerToOrganization, orgId, setActive, isPending, isError, registrationResult]);
  if (isError) {
    return <div className="flex h-dvh w-full items-center justify-center">
        <NonIdealState backRoute={{
        pathname: "/select-organization",
        query: {}
      }} className="mx-2 max-w-xl" message="Error loading workspace" variant="error" />
      </div>;
  }
  if (isPending || registrationResult == null) {
    return <AppLoader />;
  }
  return children;
};