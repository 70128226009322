import type { LoggerOptions } from "pino";
import { pino } from "pino";

export type IChildLoggerOptions = pino.ChildLoggerOptions;
export type IBindings = pino.Bindings;
export type ILogger = pino.Logger;
export type IStream = pino.DestinationStream;

Error.stackTraceLimit = Infinity;

export function createLogger<ChildOptions extends IChildLoggerOptions>(
  bindings: IBindings,
  opts: ChildOptions,
): ILogger {
  const isDev =
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test" ||
    process.env.VITE_DISABLE_EXTERNAL_LOGGING === "true";

  const pinoConf: LoggerOptions = {
    level: isDev ? "trace" : "info",
    browser: {
      asObject: true,
    },
  };

  const pinoTransport: IStream = process.stdout;
  // if (typeof window === "undefined" && !isDev) {
  //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  //   pinoTransport = transport({
  //     target: "@baselime/pino-transport",
  //     options: { baselimeApiKey: process.env.BASELIME_KEY },
  //   });
  // } else if (typeof process !== "undefined") {
  //   pinoTransport = process.stdout;
  // }

  const parentLogger = pino(pinoConf, pinoTransport);

  return parentLogger.child({ ...bindings, env: process.env.NODE_ENV }, opts);
}

export const createModuleLogger = <ModuleLoggerOptions extends IChildLoggerOptions>(
  bindings: IBindings & { module: string },
  moduleOpts?: ModuleLoggerOptions,
): {
  moduleLogger: ILogger;
  createFileLogger: <FileLoggerOptions extends IChildLoggerOptions>(
    file: string,
    fileOpts?: FileLoggerOptions | undefined,
  ) => ILogger;
} => {
  const moduleLogger = createLogger(bindings, moduleOpts ?? {});

  return {
    moduleLogger,
    createFileLogger: (file, fileOpts) => moduleLogger.child({ file }, fileOpts ?? {}),
  };
};
