"use client";

import { map, mapValues } from "@archetype/utils";
import { Command as CommandPrimitive, useCommandState } from "cmdk";
import * as React from "react";
import { useEffect } from "react";
import { useDeepCompareEffect } from "react-use";
import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { Badge } from "./badge";
import { Command, CommandGroup, CommandItem, CommandList } from "./command";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
export interface IMultiSelectOption<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
  /** fixed option that can't be removed. */
  fixed?: boolean;
  /** Group the options by providing key. */
  [key: string]: T | string | boolean | undefined;
}
interface IGroupOption<T = string> {
  [key: string]: IMultiSelectOption<T>[];
}
interface IMultiSelect<T = string> {
  value?: IMultiSelectOption<T>[];
  defaultOptions?: IMultiSelectOption<T>[];
  /** manually controlled options */
  options?: IMultiSelectOption<T>[];
  placeholder?: string;
  /** Loading component. */
  loadingIndicator?: React.ReactNode;
  /** Empty component. */
  emptyIndicator?: React.ReactNode;
  /** Debounce time for async search. Only work with `onSearch`. */
  delay?: number;
  /**
   * Only work with `onSearch` prop. Trigger search when `onFocus`.
   * For example, when user click on the input, it will trigger the search to get initial options.
   **/
  triggerSearchOnFocus?: boolean;
  onSearch?: (value: string) => void;
  onChange?: (options: IMultiSelectOption<T>[]) => void;
  /** Limit the maximum number of selected options. */
  maxSelected?: number;
  /** When the number of selected options exceeds the limit, the onMaxSelected will be called. */
  onMaxSelected?: (maxLimit: number) => void;
  /** Hide the placeholder when there are options selected. */
  hidePlaceholderWhenSelected?: boolean;
  disabled?: boolean;
  /** Group the options base on provided key. */
  groupBy?: string;
  className?: string;
  badgeClassName?: string;
  /**
   * First item selected is a default behavior by cmdk. That is why the default is true.
   * This is a workaround solution by add a dummy item.
   *
   * @reference: https://github.com/pacocoursey/cmdk/issues/171
   */
  selectFirstItem?: boolean;
  /** Function to call when creating a new item */
  onCreateItem?: (value: string) => void;
  /** Props of `Command` */
  commandProps?: React.ComponentPropsWithoutRef<typeof Command>;
  /** Props of `CommandInput` */
  inputProps?: Omit<React.ComponentPropsWithRef<typeof CommandPrimitive.Input>, "value" | "placeholder" | "disabled">;
  /** hide the clear all button. */
  hideClearAllButton?: boolean;
  /** Custom renderer for options */
  optionRenderer?: (option: IMultiSelectOption<T>) => React.ReactNode;
  /** Indicates if the component is in a loading state */
  isLoading?: boolean;
  /** Indicates if the component should use the small variant */
  small?: boolean;
  ref?: React.Ref<IMultiSelectRef<T>>;
}
export interface IMultiSelectRef<T = string> {
  selectedValue: IMultiSelectOption<T>[];
  input: HTMLInputElement;
  focus: () => void;
  reset: () => void;
}
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500);
    return (): void => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
}
function convertToGroupOptions<T>(options: IMultiSelectOption<T>[], groupBy?: string): IGroupOption<T> {
  if (options.length === 0) {
    return {};
  }
  if (groupBy == null) {
    return {
      "": options
    };
  }
  const groupOption: IGroupOption<T> = {};
  options.forEach(option => {
    const key = option[groupBy] as string | undefined ?? "";
    const existing = groupOption[key];
    if (existing == null) {
      groupOption[key] = [option];
    } else {
      groupOption[key] = [...existing, option];
    }
  });
  return groupOption;
}
function removeSelectedOptions<T>(groupOption: IGroupOption<T>, picked: IMultiSelectOption<T>[]): IGroupOption<T> {
  return mapValues(groupOption, value => value.filter(val => !picked.some(p => p.value === val.value)));
}

/**
 * The `CommandEmpty` of shadcn/ui will cause the cmdk empty not rendering correctly.
 * So we create one and copy the `Empty` implementation from `cmdk`.
 *
 * @reference: https://github.com/hsuanyi-chou/shadcn-ui-expansions/issues/34#issuecomment-1949561607
 **/
const CommandEmpty = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CommandPrimitive.Empty>>(({
  className,
  ...props
}, forwardedRef) => {
  const render = useCommandState(state => state.filtered.count === 0);
  if (!render) return null;
  return <div ref={forwardedRef} className={cn("py-6 text-center text-base", className)} cmdk-empty="" role="presentation" {...props} />;
});
CommandEmpty.displayName = "CommandEmpty";
function MultiSelect<T extends string = string>({
  value,
  onChange,
  placeholder,
  defaultOptions: arrayDefaultOptions = [],
  options: arrayOptions,
  delay,
  onSearch,
  loadingIndicator,
  emptyIndicator,
  maxSelected = Number.MAX_SAFE_INTEGER,
  onMaxSelected,
  hidePlaceholderWhenSelected,
  disabled,
  groupBy,
  className,
  badgeClassName,
  selectFirstItem = true,
  onCreateItem,
  triggerSearchOnFocus = false,
  commandProps,
  inputProps,
  hideClearAllButton = false,
  optionRenderer,
  isLoading = false,
  small = false,
  ref
}: IMultiSelect<T>): React.ReactNode {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [onScrollbar, setOnScrollbar] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [selected, setSelected] = React.useState<IMultiSelectOption<T>[]>(value || []);
  const [options, setOptions] = React.useState<IGroupOption<T>>(convertToGroupOptions(arrayDefaultOptions, groupBy));
  const [inputValue, setInputValue] = React.useState("");
  const debouncedSearchTerm = useDebounce(inputValue, delay ?? 500);
  React.useImperativeHandle(ref, () => ({
    selectedValue: [...selected],
    // Disable non null assertion because we cant make this conditional
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should be safe
    input: inputRef.current!,
    focus: (): void => inputRef.current?.focus(),
    reset: (): void => setSelected([])
  }), [selected]);
  useDeepCompareEffect(() => {
    setOptions(convertToGroupOptions(arrayOptions ?? [], groupBy));
  }, [arrayOptions, groupBy]);
  const handleClickOutside = (event: MouseEvent | TouchEvent): void => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setOpen(false);
      inputRef.current.blur();
    }
  };
  const handleUnselect = React.useCallback((option: IMultiSelectOption<T>) => {
    const newOptions = selected.filter(s => s.value !== option.value);
    setSelected(newOptions);
    onChange?.(newOptions);
  }, [onChange, selected]);
  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === "Delete" || e.key === "Backspace") {
        if (input.value === "" && selected.length > 0) {
          const lastSelectOption = selected[selected.length - 1];

          // If last item is fixed, we should not remove it.
          if (lastSelectOption?.fixed !== true) {
            lastSelectOption != null && handleUnselect(lastSelectOption);
          }
        }
      }
      // This is not a default behavior of the <input /> field
      if (e.key === "Escape") {
        input.blur();
      }
    }
  }, [handleUnselect, selected]);
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    }
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [open]);
  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);
  useEffect(() => {
    /** If `onSearch` is provided, do not trigger options updated. */
    if (arrayOptions == null || onSearch) {
      return;
    }
    const newOption = convertToGroupOptions(arrayOptions, groupBy);
    if (JSON.stringify(newOption) !== JSON.stringify(options)) {
      setOptions(newOption);
    }
  }, [arrayDefaultOptions, arrayOptions, groupBy, onSearch, options]);
  useEffect(() => {
    if (!onSearch || !open) return;
    if (triggerSearchOnFocus) {
      onSearch(debouncedSearchTerm);
    }
    if (debouncedSearchTerm !== "") {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, groupBy, open, triggerSearchOnFocus, onSearch]);
  const creatable = onCreateItem != null;
  const EmptyItem = React.useCallback(() => {
    if (emptyIndicator !== true) return undefined;

    // For async search that showing emptyIndicator
    if (onSearch && !creatable && Object.keys(options).length === 0) {
      return <CommandItem disabled value="-">
          {emptyIndicator}
        </CommandItem>;
    }
    return <CommandEmpty>{emptyIndicator}</CommandEmpty>;
  }, [creatable, emptyIndicator, onSearch, options]);
  const selectables = React.useMemo<IGroupOption<T>>(() => removeSelectedOptions(options, selected), [options, selected]);

  /** Avoid Creatable Selector freezing or lagging when paste a long string. */
  const commandFilter = React.useCallback(() => {
    if (commandProps?.filter) {
      return commandProps.filter;
    }
    if (creatable) {
      return (v: string, search: string): number => {
        return v.toLowerCase().includes(search.toLowerCase()) ? 1 : -1;
      };
    }

    // Using default filter in `cmdk`. We don't have to provide it.
    return undefined;
  }, [creatable, commandProps?.filter]);
  const handleCommandKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    handleKeyDown(e);
    commandProps?.onKeyDown?.(e);
  }, [commandProps, handleKeyDown]);
  const handleInputValueChange = React.useCallback((v: string) => {
    setInputValue(v);
    inputProps?.onValueChange?.(v);
  }, [inputProps]);
  const handleWrapperClick = React.useCallback(() => {
    if (disabled === true) return;
    inputRef.current?.focus();
  }, [disabled]);
  const handleBadgeKeyDown = React.useCallback((opt: IMultiSelectOption<T>) => (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Delete" || e.key === "Backspace") {
      e.preventDefault();
      e.stopPropagation();
      handleUnselect(opt);
    }
  }, [handleUnselect]);
  const handleBadgeMouseDown = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const handleBadgeClick = React.useCallback((opt: IMultiSelectOption<T>) => (): void => {
    handleUnselect(opt);
  }, [handleUnselect]);
  const handleInputBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (!onScrollbar) {
      setOpen(false);
    }
    setInputValue("");
    inputProps?.onBlur?.(event);
  }, [onScrollbar, inputProps]);
  const handleInputFocus = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setOpen(true);
    triggerSearchOnFocus && onSearch?.(debouncedSearchTerm);
    inputProps?.onFocus?.(event);
  }, [onSearch, debouncedSearchTerm, triggerSearchOnFocus, inputProps]);
  const handleClearAll = React.useCallback(() => {
    setSelected(selected.filter(s => s.fixed));
    onChange?.(selected.filter(s => s.fixed));
  }, [selected, onChange]);
  const handleListMouseLeave = React.useCallback(() => {
    setOnScrollbar(false);
  }, []);
  const handleListMouseEnter = React.useCallback(() => {
    setOnScrollbar(true);
  }, []);
  const handleListMouseUp = React.useCallback(() => {
    inputRef.current?.focus();
  }, []);
  const handleItemMouseDown = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  // Necessary so blur doesn't trigger when opening the popover
  const handlePopoverOpenAutoFocus = React.useCallback((e: Event) => {
    e.preventDefault();
  }, []);
  const handleItemSelect = React.useCallback((option: IMultiSelectOption<T>) => (): void => {
    if (selected.length >= maxSelected) {
      onMaxSelected?.(selected.length);
      return;
    }
    setInputValue("");
    const newOptions = [...selected, {
      value: option.value,
      label: option.label
    }];
    setSelected(newOptions);
    onChange?.(newOptions);
  }, [onChange, maxSelected, onMaxSelected, selected]);
  const handleCreateItem = React.useCallback(async (): Promise<void> => {
    onCreateItem?.(inputValue);
    setOpen(false);
  }, [inputValue, onCreateItem]);
  const CreatableItem = (): React.ReactNode => {
    if (!creatable) return undefined;
    return <CommandGroup data-sentry-element="CommandGroup" data-sentry-component="CreatableItem" data-sentry-source-file="multiselect.tsx">
        <CommandItem className="cursor-pointer" value="create-new" onMouseDown={handleItemMouseDown}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
      onSelect={handleCreateItem} data-sentry-element="CommandItem" data-sentry-source-file="multiselect.tsx">
          <span className="flex items-center gap-x-1">
            <Icon name="plus" data-sentry-element="Icon" data-sentry-source-file="multiselect.tsx" />
            <span>Create new</span>
          </span>
        </CommandItem>
      </CommandGroup>;
  };
  return <Popover open={open} data-sentry-element="Popover" data-sentry-component="MultiSelect" data-sentry-source-file="multiselect.tsx">
      <Command ref={dropdownRef} {...commandProps} className={cn("h-auto overflow-visible bg-transparent", commandProps?.className)} filter={commandFilter()} shouldFilter={commandProps?.shouldFilter !== undefined ? commandProps.shouldFilter : !onSearch} // When onSearch is provided, we don't want to filter the options. You can still override it.
    onKeyDown={handleCommandKeyDown} data-sentry-element="Command" data-sentry-source-file="multiselect.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="multiselect.tsx">
          <div className={cn(inputVariants({
          size: small ? "small" : "default"
        }), "h-fit min-h-10", {
          "cursor-text": disabled !== true && selected.length > 0,
          "bg-muted-background": disabled === true
        }, className)} onClick={handleWrapperClick}>
            <div className="relative flex grow flex-wrap gap-1">
              {selected.map(option => {
              return <Badge key={option.value} className={cn(badgeClassName)} colorVariant={option.fixed === true ? "gray" : "white"} iconRight="x" interactive={option.fixed !== true} size="sm" onClick={handleBadgeClick(option)} onKeyDown={handleBadgeKeyDown(option)} onMouseDown={handleBadgeMouseDown}>
                    {option.label}
                  </Badge>;
            })}
              {/* Avoid having the "Search" Icon */}
              <CommandPrimitive.Input {...inputProps} ref={inputRef} className={cn("w-full flex-1 bg-transparent outline-none placeholder:text-muted-foreground", inputValue === "" && "!text-muted-foreground", selected.length > 0 && "pl-1", small ? "text-base" : "text-lg", inputProps?.className)} disabled={disabled} placeholder={hidePlaceholderWhenSelected === true && selected.length > 0 ? "" : placeholder} value={inputValue} onBlur={handleInputBlur} onFocus={handleInputFocus} onValueChange={handleInputValueChange} data-sentry-element="unknown" data-sentry-source-file="multiselect.tsx" />
              <button className={cn("relative top-px mr-1 p-0 text-muted-foreground", small ? "bottom-1" : "bottom-1", (hideClearAllButton || disabled === true || selected.length === 0 || selected.filter(s => s.fixed).length === selected.length) && "hidden")} type="button" onClick={handleClearAll}>
                <Icon className={cn(small && "size-3")} name="x" data-sentry-element="Icon" data-sentry-source-file="multiselect.tsx" />
              </button>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent autoFocus={false} className="max-h-[300px] w-full min-w-72 p-0 shadow outline-none animate-in" style={{
        width: "var(--radix-popover-trigger-width)"
      }} onOpenAutoFocus={handlePopoverOpenAutoFocus} data-sentry-element="PopoverContent" data-sentry-source-file="multiselect.tsx">
          <CommandList className={cn("w-full", small ? "text-base" : "text-lg")} onMouseEnter={handleListMouseEnter} onMouseLeave={handleListMouseLeave} onMouseUp={handleListMouseUp} data-sentry-element="CommandList" data-sentry-source-file="multiselect.tsx">
            {isLoading ? <div className="flex items-center justify-center p-4">
                {loadingIndicator != null ? loadingIndicator : <Loader />}
              </div> : <>
                {EmptyItem()}
                {CreatableItem()}
                {!selectFirstItem && <CommandItem className="hidden" value="-" />}
                {map(selectables, (dropdowns, key) => <CommandGroup key={key} className="h-full overflow-auto" heading={key}>
                    <>
                      {dropdowns.map(option => {
                  return <CommandItem key={option.value} className={cn("cursor-pointer", option.disabled === true && "cursor-default text-muted-foreground")} disabled={option.disabled} value={option.value} onMouseDown={handleItemMouseDown} onSelect={handleItemSelect(option)}>
                            {optionRenderer ? optionRenderer(option) : option.label}
                          </CommandItem>;
                })}
                    </>
                  </CommandGroup>)}
              </>}
          </CommandList>
        </PopoverContent>
      </Command>
    </Popover>;
}
MultiSelect.displayName = "MultiSelect";
export { MultiSelect };