import { z } from "zod";

import { BrandedWithGenerator } from "./idGenerationUtils";

/**
 * id identifying a column on an entity type in the data model
 */
export const ColumnId = BrandedWithGenerator.from("ColumnId", {
  prefix: "col",
  kind: "lowCardinalityScoped",
});
export const ValidationId = BrandedWithGenerator.from("ValidationId", {
  prefix: "val",
  kind: "sortable",
});
export const EntityTypeCompositeId = z.string().brand("EntityTypeCompositeId");
export const CompositeTypeUnionedSetId = z.string().brand("ColumnTypeUnionedSetId");
/**
 * id identifying an internal reference inside a composite type in the data model
 */
export const CompositeTypeInternalReferenceId = z.string().brand("CompositeTypeInternalReferenceId");
/**
 * id identifying a feature transaction, which has a raw text input and a list of features and a summary as output
 */
export const FeatureTransactionId = z.string().brand("FeatureTransactionId");
/**
 * id identifying a structured feature
 */
export const FeatureId = z.string().brand("FeatureId");

/**
 * id identifying a requirement, which is a type of end user of the application
 */
export const RequirementId = z.string().brand("RequirementId");

/**
 * id identifying an actual entity, its primary key value as a string
 */
export const EntityId = BrandedWithGenerator.from("EntityId", {
  prefix: "entity",
  kind: undefined,
});

export const RelationRecordId = BrandedWithGenerator.from("RelationRecordId", {
  prefix: "relrec",
  kind: "sortable",
});

export const ViewFieldId = z.string().brand("ViewFieldId");
/**
 * id identifying an action log, which is a record of an action that was taken by an end user
 */
export const ActionLogId = BrandedWithGenerator.from("ActionLogId", {
  prefix: "action_log",
  kind: "sortable",
});
export const EntityCommentId = BrandedWithGenerator.from("EntityCommentId", {
  prefix: "comment",
  kind: "sortable",
});
export const FieldComputationId = BrandedWithGenerator.from("FieldComputationId", {
  prefix: "computation",
  kind: "sortable",
});

export const SlotId = z.string().brand("SlotId");
export const LayoutVariantId = z.string().uuid().brand("LayoutVariantId");
export const LayoutConfigurationId = z.string().brand("LayoutConfigurationId");
export const LayoutParameterId = z.string().uuid().brand("LayoutParameterId");

export const StateId = BrandedWithGenerator.from("StateId", {
  prefix: "step",
  kind: "lowCardinalityScoped",
});

export const TransitionId = BrandedWithGenerator.from("TransitionId", {
  prefix: "act",
  kind: "lowCardinalityScoped",
});

export const ApplicationId = BrandedWithGenerator.from("ApplicationId", {
  prefix: "app",
  kind: undefined,
});

export const ApplicationGroupId = BrandedWithGenerator.from("ApplicationGroupId", {
  prefix: "prcs",
  kind: undefined,
});
export const UserId = BrandedWithGenerator.from("UserId", {
  prefix: "user",
  kind: undefined,
});
export const ClerkUserId = z.string().brand("ClerkUserId"); // TODO @sspringer this is actually a KSUID which zod doesnt support
export const ClerkOrganizationId = z.string().brand("ClerkOrganizationId"); // TODO @sspringer this is actually a KSUID which zod doesnt support
export const OrganizationId = BrandedWithGenerator.from("OrganizationId", {
  prefix: "org",
  kind: "lowCardinalityScoped",
});

export const OnboardingSessionId = BrandedWithGenerator.from("OnboardingSessionId", {
  prefix: "new",
  kind: undefined,
});
export const ChainRunId = z.string().uuid().brand("ChainRunId");
export const ApplicationGroupFavoriteId = z.string().uuid().brand("ApplicationGroupFavoriteId");
export const ApplicationGroupEventId = z.string().uuid().brand("ApplicationGroupEventId");
export const ApplicationEventId = z.string().uuid().brand("ApplicationEventId");
export const LandingPageId = BrandedWithGenerator.from("LandingPageId", {
  prefix: "lp",
  kind: "sortable",
});
export const MarketingVerticalId = z.string().uuid().brand("MarketingVerticalId");
export const MarketingIndustryId = z.string().uuid().brand("MarketingIndustryId");

export const StoreKey = z.string().brand("StoreKey");

export const FileId = z.string().brand("fileId");
// —————————————————————————————————————————————————————————————————————————————————————————————————————

export type IColumnId = z.infer<typeof ColumnId>;
export type IValidationId = z.infer<typeof ValidationId>;
export type IEntityTypeCompositeId = z.infer<typeof EntityTypeCompositeId>;
export type ICompositeTypeUnionedSetId = z.infer<typeof CompositeTypeUnionedSetId>;
export type ICompositeTypeInternalReferenceId = z.infer<typeof CompositeTypeInternalReferenceId>;
export type IFeatureTransactionId = z.infer<typeof FeatureTransactionId>;
export type IFeatureId = z.infer<typeof FeatureId>;
export type IRequirementId = z.infer<typeof RequirementId>;
export type IViewFieldId = z.infer<typeof ViewFieldId>;

export type IEntityId = z.infer<typeof EntityId>;
export type IRelationRecordId = z.infer<typeof RelationRecordId>;

export type IActionLogId = z.infer<typeof ActionLogId>;
export type IEntityCommentId = z.infer<typeof EntityCommentId>;
export type IFieldComputationId = z.infer<typeof FieldComputationId>;

export type ISlotId = z.infer<typeof SlotId>;
export type ILayoutVariantId = z.infer<typeof LayoutVariantId>;
export type ILayoutConfigurationId = z.infer<typeof LayoutConfigurationId>;
export type ILayoutParameterId = z.infer<typeof LayoutParameterId>;

export type IStateId = z.infer<typeof StateId>;
export type ITransitionId = z.infer<typeof TransitionId>;

export type IApplicationId = z.infer<typeof ApplicationId>;
export type IApplicationGroupId = z.infer<typeof ApplicationGroupId>;

export type IOnboardingSessionId = z.infer<typeof OnboardingSessionId>;

export type IChainRunId = z.infer<typeof ChainRunId>;
export type IApplicationGroupFavoriteId = z.infer<typeof ApplicationGroupFavoriteId>;
export type IUserId = z.infer<typeof UserId>;
export type IClerkUserId = z.infer<typeof ClerkUserId>;
export type IClerkOrganizationId = z.infer<typeof ClerkOrganizationId>;
export type IOrganizationId = z.infer<typeof OrganizationId>;
export type IApplicationGroupEventId = z.infer<typeof ApplicationGroupEventId>;
export type IApplicationEventId = z.infer<typeof ApplicationEventId>;

export type IStoreKey = z.infer<typeof StoreKey>;
export type ILandingPageId = z.infer<typeof LandingPageId>;
export type IMarketingVerticalId = z.infer<typeof MarketingVerticalId>;
export type IMarketingIndustryId = z.infer<typeof MarketingIndustryId>;

export type IFileId = z.infer<typeof FileId>;
