import { z } from "zod";

export const ActionButtonComponentDefinitionId = "41a671af-4c69-45cf-b0dc-549ac8adfeaf";
export const BoardComponentDefinitionId = "b0b7fdf3-34fc-4e23-8119-4ac7e7b09560";
export const ChartComponentDefinitionId = "e7fb1b25-5631-4a88-97ce-14dc1735ed21";
export const DescriptionListComponentDefinitionId = "8ba865e5-c8cd-4fcc-a019-78e7f73876d2";
export const EntityListComponentDefinitionId = "a44f7c69-64e7-4855-a1b8-af59191d7823";
export const EntityDetailsComponentDefinitionId = "1dca1af5-38bd-47f3-bc94-1ea1d3e12e07";
export const EventListComponentDefinitionId = "3e7d9113-795a-4a32-b92e-74c1b916eedb";
export const FilterListComponentDefinitionId = "3214e0a0-c194-43d8-ba04-013afd3a4d58";
export const ImageComponentDefinitionId = "7f482689-0c06-452c-9f70-696842a3f659";
export const ListComponentDefinitionId = "120768df-acf7-465c-9d26-d6c275392985";
export const MapComponentDefinitionId = "d1d33584-77b2-4778-b9ea-ec3a8c972046";
export const MetricCardComponentDefinitionId = "6bbcbda3-4c64-48bd-a787-307cccf08f88";
export const SearchInputComponentDefinitionId = "6ca132a6-0b99-4040-a09a-06c7e19b1f0b";
export const TableComponentDefinitionId = "e8e7ed4b-2040-44c1-b2ac-ce334f19a5cf";
export const TableGroupedComponentDefinitionId = "70e396b1-a32e-41c3-8d2c-9b96968a6a5a";
export const CardListComponentDefinitionId = "b6a7c426-685e-486f-b853-bdfb5cb4b82a";

// Not branded
export const ComponentDefinitionId = z.enum([
  ActionButtonComponentDefinitionId,
  BoardComponentDefinitionId,
  ChartComponentDefinitionId,
  DescriptionListComponentDefinitionId,
  EntityListComponentDefinitionId,
  EntityDetailsComponentDefinitionId,
  EventListComponentDefinitionId,
  FilterListComponentDefinitionId,
  ImageComponentDefinitionId,
  ListComponentDefinitionId,
  MapComponentDefinitionId,
  MetricCardComponentDefinitionId,
  SearchInputComponentDefinitionId,
  TableComponentDefinitionId,
  TableGroupedComponentDefinitionId,
  CardListComponentDefinitionId,
]);

export type IComponentDefinitionId = z.infer<typeof ComponentDefinitionId>;
