import type { IOrganizationId } from "@archetype/ids";
import { OrganizationId } from "@archetype/ids";
import type { NextRouter } from "next/router";
export const routeRequiresAuthentication = (router: NextRouter): boolean => {
  return !["/sign-in/[[...index]]", "/sign-up/[[...index]]", "/session"].includes(router.pathname);
};
export const getRouterOrganizationId = (router: NextRouter): IOrganizationId | undefined => {
  const orgIdQueryParam = router.query.orgId;
  const orgId = Array.isArray(orgIdQueryParam) ? orgIdQueryParam.join("") : orgIdQueryParam;
  return orgId != null ? OrganizationId.parse(orgId) : undefined;
};