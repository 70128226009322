import { pickBy as _pickBy } from "lodash";

/**
 * Creates a new object with all undefined values removed from the input object.
 *
 * @param rec The object to process
 * @returns A new object with the same keys but without undefined values
 */
export function unpartialRecord<const T extends { [key: string | number | symbol]: unknown }>(
  rec: T,
): { [key in keyof T]-?: T[key] extends undefined ? never : Exclude<T[key], undefined> } {
  return _pickBy(rec, (value) => typeof value !== "undefined") as {
    [key in keyof T]-?: T[key] extends undefined ? never : Exclude<T[key], undefined>;
  };
}
