import type { IOrganizationId } from "@archetype/ids";
import type { HTTPHeaders } from "@trpc/client";

/**
 * Stores a global context with the current organization id for all trpc calls
 * The organization id is used as part of authorizing endpoints
 */
type IOrganizationGlobalTrpcContext = {
  getOrganizationId: () => IOrganizationId | undefined;
  setOrganizationId: (organizationId: IOrganizationId | undefined) => void;
};

const createOrganizationGlobalContext = (): IOrganizationGlobalTrpcContext => {
  let _organizationId: IOrganizationId | undefined;

  const getOrganizationId = (): IOrganizationId | undefined => {
    return _organizationId;
  };
  const setOrganizationId = (organizationId: IOrganizationId | undefined): void => {
    _organizationId = organizationId;
  };

  return {
    getOrganizationId,
    setOrganizationId,
  };
};

export const organizationGlobalContext = createOrganizationGlobalContext();

export const getRequestHeadersForOrganizationContext = (): HTTPHeaders => {
  const organizationId = organizationGlobalContext.getOrganizationId();

  return organizationId != null ? { "x-organization-id": organizationId } : {};
};
