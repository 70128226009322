// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { omitBy as _omitBy } from "lodash";

/**
 * Creates an object composed of properties that the `predicate` returns falsy for.
 * The predicate is invoked with two arguments: (value, key).
 *
 * @param obj The source object
 * @param predicate The function invoked per property
 * @returns Returns the new object
 */
export function omitBy<
  const Obj extends { [key: string | number | symbol]: unknown },
  Predicate extends (x: Obj[keyof Obj], key: keyof Obj) => boolean,
>(obj: Obj, predicate: Predicate): Obj;
export function omitBy<
  const Obj extends { [key: string | number | symbol]: Wide },
  Wide,
  Narrow extends Wide,
  Predicate extends (x: Wide, key: keyof Obj) => x is Narrow,
>(obj: Obj, predicate: Predicate): Omit<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]> {
  return _omitBy(obj, predicate) as Omit<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]>;
}
