// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { filter as _filter } from "lodash";

/**
 * Creates an array of values by filtering each property of `obj` through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns An array of the results of running `iteratee` for each property
 */
export function filter<const Key extends string, const Value>(
  obj: Record<Key, Value> | undefined,
  iteratee: (value: Value, key: Key) => boolean,
): Value[] {
  return _filter(obj, iteratee as (value: Value, key: string) => boolean) as Value[];
}
