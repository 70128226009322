import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useCallback } from "react";
import { cn } from "../../lib/utils";
type ITruncateRenderer = (state: {
  hiddenItemsCount: number;
}) => React.ReactNode;
export type ITruncateList = {
  renderTruncator: ITruncateRenderer;
  children?: React.ReactNode;
  alwaysShowTruncator?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
const rectContainsRect = (parent: DOMRect, child: DOMRect): boolean => {
  return child.top >= parent.top && child.bottom <= parent.bottom && child.left >= parent.left && child.right <= parent.right;
};
export const TruncateList: React.FC<ITruncateList> = ({
  renderTruncator,
  alwaysShowTruncator,
  children,
  className,
  style
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const truncate = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const childNodes = Array.from(containerRef.current.children) as HTMLElement[];

    // If there's only one item, truncate the text instead of the list
    if (childNodes.length === 3) {
      // 1 item + 2 truncators
      const itemEl = childNodes[1];
      if (itemEl) {
        itemEl.style.overflow = "hidden";
        itemEl.style.textOverflow = "ellipsis";
        itemEl.style.whiteSpace = "nowrap";
        itemEl.style.maxWidth = "100%";
      }
      return;
    }

    //
    // Put the list in its initial state.
    //
    // Change from a scrollable container to overflow: hidden during hydration
    containerRef.current.style.overflow = "hidden";
    // Show all items, hide all truncators.
    for (let i = 0; i < childNodes.length; ++i) {
      const targetItem = childNodes[i];
      if (targetItem) {
        targetItem.hidden = i % 2 === 0;
      }
    }

    //
    // Test if truncation is necessary.
    //
    if (alwaysShowTruncator === true) {
      // if the last truncator fits, exit
      const truncatorEl = childNodes[childNodes.length - 1];
      if (truncatorEl) {
        truncatorEl.hidden = false;
        if (rectContainsRect(containerRef.current.getBoundingClientRect(), truncatorEl.getBoundingClientRect())) {
          return;
        }
        truncatorEl.hidden = true;
      }
    } else {
      // if the last item fits, exit
      const itemEl = childNodes[childNodes.length - 2];
      if (itemEl && rectContainsRect(containerRef.current.getBoundingClientRect(), itemEl.getBoundingClientRect())) {
        return;
      }
    }

    //
    // Truncation is necessary - binary search to find the last truncator that can fit.
    //
    const numTruncators = Math.floor((childNodes.length - 1) / 2);
    let left = 0;
    let right = numTruncators - 1;
    let truncatorIndex: number | null = null;
    while (left <= right) {
      const middle = Math.floor((left + right) / 2);

      // show all items before the truncator
      for (let i = 0; i < middle; i += 1) {
        const itemEl = childNodes[i * 2 + 1];
        if (itemEl) {
          itemEl.hidden = false;
        }
      }
      // hide all items after the truncator
      for (let i = middle; i < numTruncators; i += 1) {
        const itemEl = childNodes[i * 2 + 1];
        if (itemEl) {
          itemEl.hidden = true;
        }
      }
      const truncatorEl = childNodes[middle * 2];
      if (truncatorEl) {
        truncatorEl.hidden = false;

        // check if this truncator fits
        if (rectContainsRect(containerRef.current.getBoundingClientRect(), truncatorEl.getBoundingClientRect())) {
          truncatorIndex = middle;
          left = middle + 1;
        } else {
          right = middle - 1;
        }
        truncatorEl.hidden = true;
      }
    }

    // If we didn't find a truncator that fits, everything will be hidden at this point and we can exit early
    if (truncatorIndex === null) {
      return;
    }

    //
    // Now we have found the last truncator that fits, show it.
    //
    // show all items before the truncator
    for (let i = 0; i < truncatorIndex; i += 1) {
      const itemEl = childNodes[i * 2 + 1];
      if (itemEl) {
        itemEl.hidden = false;
      }
    }
    // hide all items after truncator
    for (let i = truncatorIndex; i < numTruncators; i += 1) {
      const itemEl = childNodes[i * 2 + 1];
      if (itemEl) {
        itemEl.hidden = true;
      }
    }
    const truncatorEl = childNodes[truncatorIndex * 2];
    if (truncatorEl) {
      truncatorEl.hidden = false;
    }
  }, [alwaysShowTruncator]);

  // Set up a resize observer
  useLayoutEffect(() => {
    const el = containerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const _ of entries) {
        truncate();
      }
    });
    if (el) {
      resizeObserver.observe(el);
    }
    return (): void => {
      if (el) {
        resizeObserver.unobserve(el);
      }
    };
  }, [truncate]);
  const childArray = React.Children.toArray(children);
  const items = useMemo(() => {
    return childArray.map((item, i) =>
    // eslint-disable-next-line react/no-array-index-key -- index based content with items elements
    <React.Fragment key={i}>
        <li hidden>{renderTruncator({
          hiddenItemsCount: childArray.length - i
        })}</li>
        <li className="whitespace-nowrap">{item}</li>
      </React.Fragment>);
  }, [childArray, renderTruncator]);
  return <ul ref={containerRef} className={cn("flex items-center gap-x-1 overflow-hidden", className)} style={style} data-sentry-component="TruncateList" data-sentry-source-file="truncate-list.tsx">
      {items}

      <li hidden className="whitespace-nowrap">
        {renderTruncator({
        hiddenItemsCount: 0
      })}
      </li>
    </ul>;
};