import { AlertDialogTrigger } from "@radix-ui/react-alert-dialog";
import * as React from "react";
import { useCallback, useState } from "react";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "./alert-dialog";
import { Input } from "./input";
export interface IConfirmButton {
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmTitle?: string;
  confirmDescription?: string;
  confirmationWord: string;
  confirmLabel?: string;
  cancelLabel?: string;
}
export const ConfirmButton: React.FC<IConfirmButton> = ({
  children,
  onConfirm,
  onCancel,
  confirmTitle = "Are you sure?",
  confirmDescription = "This action cannot be undone.",
  confirmationWord,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const handleConfirm = useCallback(() => {
    if (inputValue === confirmationWord) {
      onConfirm();
      setIsOpen(false);
      setInputValue("");
    }
  }, [inputValue, confirmationWord, onConfirm]);
  const handleCancel = useCallback(() => {
    setIsOpen(false);
    setInputValue("");
    onCancel?.();
  }, [onCancel]);
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);
  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setInputValue("");
      onCancel?.();
    }
    setIsOpen(open);
  }, [onCancel]);
  return <AlertDialog open={isOpen} onOpenChange={handleOpenChange} data-sentry-element="AlertDialog" data-sentry-component="ConfirmButton" data-sentry-source-file="confirm-button.tsx">
      <AlertDialogTrigger asChild data-sentry-element="AlertDialogTrigger" data-sentry-source-file="confirm-button.tsx">{children}</AlertDialogTrigger>
      <AlertDialogContent data-sentry-element="AlertDialogContent" data-sentry-source-file="confirm-button.tsx">
        <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="confirm-button.tsx">
          <AlertDialogTitle data-sentry-element="AlertDialogTitle" data-sentry-source-file="confirm-button.tsx">{confirmTitle}</AlertDialogTitle>
          <AlertDialogDescription className="space-y-4" data-sentry-element="AlertDialogDescription" data-sentry-source-file="confirm-button.tsx">
            {confirmDescription}
            <div>
              <p className="mb-2 text-sm">
                Please type <span className="font-medium">{confirmationWord}</span> to confirm:
              </p>
              <Input placeholder={`Type ${confirmationWord} to confirm`} value={inputValue} onChange={handleInputChange} data-sentry-element="Input" data-sentry-source-file="confirm-button.tsx" />
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter data-sentry-element="AlertDialogFooter" data-sentry-source-file="confirm-button.tsx">
          <AlertDialogCancel onClick={handleCancel} data-sentry-element="AlertDialogCancel" data-sentry-source-file="confirm-button.tsx">{cancelLabel}</AlertDialogCancel>
          <AlertDialogAction disabled={inputValue !== confirmationWord} onClick={handleConfirm} data-sentry-element="AlertDialogAction" data-sentry-source-file="confirm-button.tsx">
            {confirmLabel}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
};
ConfirmButton.displayName = "ConfirmButton";