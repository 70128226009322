import { z } from "zod";

export const ComparisonListLayoutId = "f3b52880-2172-4339-8bc6-e5e1ec97e641";
export const FilterableListLayoutId = "13021f9c-8afa-42d5-be35-b21b0674711c";
export const SearchableListLayoutId = "974b0549-3d38-4dae-ba39-82143021964d";
export const SearchableListWithMetricsLayoutId = "2adc69a3-56a7-4e6f-9740-62adc959b650";
export const SearchableListWithFilterLayoutId = "f474bfe2-be02-4c02-98c6-bbabe4d96163";
export const SimpleListLayoutId = "1625aec1-98f4-4bcd-9f57-f86058605a37";
export const SearchableFilterableListSplitViewLayoutId = "92e3d76b-3d85-45f6-9825-3a50231b4050";

// Not branded
export const LayoutId = z.union([
  z.literal(ComparisonListLayoutId),
  z.literal(FilterableListLayoutId),
  z.literal(SearchableListLayoutId),
  z.literal(SearchableListWithMetricsLayoutId),
  z.literal(SearchableListWithFilterLayoutId),
  z.literal(SimpleListLayoutId),
  z.literal(SearchableFilterableListSplitViewLayoutId),
]);
export type ILayoutId = z.infer<typeof LayoutId>;
