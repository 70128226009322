import React, { useCallback, useState } from "react";
import type { IShapeColor } from "../../lib/shapeColor";
import { ShapeColor } from "../../lib/shapeColor";
import type { IShapeName } from "../../lib/shapeName";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import type { IShapeColorIconSize } from "./shape-color-icon";
import { ShapeColorIcon } from "./shape-color-icon";
import shapes from "./shapes.json";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
export interface IShapeColorIconEditableProps {
  shape: IShapeName;
  color: IShapeColor;
  size: IShapeColorIconSize;
  className?: string;
  onShapeSelect: (newShape: IShapeName) => Promise<void>;
  onColorSelect: (newColor: IShapeColor) => Promise<void>;
}
export const ShapeColorIconEditable: React.FC<IShapeColorIconEditableProps> = ({
  className,
  shape,
  color,
  size,
  onShapeSelect: handleShapeSelect,
  onColorSelect: handleUpdateColor
}) => {
  const [selectedTab, setSelectedTab] = useState<"color" | "shape">("color");
  const [isUpdatingColor, setIsUpdatingColor] = useState(false);
  const [isUpdatingShape, setIsUpdatingShape] = useState(false);
  const handleSelectedTabChange = useCallback((tab: string): void => {
    setSelectedTab(tab === "color" ? "color" : "shape");
  }, []);
  const handleUpdateColorInner = (newColor: IShapeColor) => (): void => {
    try {
      setIsUpdatingColor(true);
      void handleUpdateColor(newColor);
    } finally {
      setIsUpdatingColor(false);
    }
  };
  const handleShapeSelectWrapper = (newShape: IShapeName) => (): void => {
    try {
      setIsUpdatingShape(true);
      void handleShapeSelect(newShape);
    } finally {
      setIsUpdatingShape(false);
    }
  };
  return <Popover data-sentry-element="Popover" data-sentry-component="ShapeColorIconEditable" data-sentry-source-file="shape-color-icon-editable.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="shape-color-icon-editable.tsx">
        <ShapeColorIcon className={className} color={color} shape={shape} size={size} data-sentry-element="ShapeColorIcon" data-sentry-source-file="shape-color-icon-editable.tsx" />
      </PopoverTrigger>
      <PopoverContent className="flex w-full flex-col items-center space-y-4 p-4" data-sentry-element="PopoverContent" data-sentry-source-file="shape-color-icon-editable.tsx">
        <Tabs value={selectedTab} onValueChange={handleSelectedTabChange} data-sentry-element="Tabs" data-sentry-source-file="shape-color-icon-editable.tsx">
          <TabsList className="mb-2 flex items-center justify-center" small={true} data-sentry-element="TabsList" data-sentry-source-file="shape-color-icon-editable.tsx">
            <TabsTrigger small={true} value="color" data-sentry-element="TabsTrigger" data-sentry-source-file="shape-color-icon-editable.tsx">
              Color
            </TabsTrigger>
            <TabsTrigger small={true} value="shape" data-sentry-element="TabsTrigger" data-sentry-source-file="shape-color-icon-editable.tsx">
              Shape
            </TabsTrigger>
          </TabsList>
          <TabsContent className="mt-4 grid grid-cols-4 gap-2" value="color" data-sentry-element="TabsContent" data-sentry-source-file="shape-color-icon-editable.tsx">
            {ShapeColor.options.map((colorOption: IShapeColor) => <div key={colorOption} className={`cursor-pointer ${isUpdatingColor ? "opacity-50" : ""}`} onClick={handleUpdateColorInner(colorOption)}>
                <ShapeColorIcon color={colorOption} shape={shape} size="xl" />
              </div>)}
          </TabsContent>
          <TabsContent className="mt-4 grid grid-cols-4 gap-2" value="shape" data-sentry-element="TabsContent" data-sentry-source-file="shape-color-icon-editable.tsx">
            {shapes.map(shapeOption => <div key={shapeOption.shape} className={`cursor-pointer ${isUpdatingShape ? "opacity-50" : ""}`} onClick={handleShapeSelectWrapper(shapeOption.shape as IShapeName)}>
                <ShapeColorIcon color={color} shape={shapeOption.shape as IShapeName} size="xl" />
              </div>)}
          </TabsContent>
        </Tabs>
      </PopoverContent>
    </Popover>;
};