import { fromPairs } from "./fromPairs";
import { toPairs } from "./toPairs";
import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Asynchronously maps the values of an object.
 */
export function mapValuesAsync<
  const InObj extends IMatchesNeverArrayRecord<Key, OldValue>,
  const Key extends string,
  const OldValue,
  const NewValue,
>(obj: InObj, iteratee: (value: OldValue, key: Key, obj: InObj) => Promise<NewValue>): Promise<Record<Key, NewValue>> {
  return Promise.all(
    (toPairs(obj) as [Key, OldValue][]).map(([key, value]) =>
      iteratee(value, key, obj).then((newValue) => [key, newValue] as [Key, NewValue]),
    ),
  ).then(fromPairs);
}
