import { Avatar, AvatarFallback, AvatarImage } from "../../atoms/avatar";
import { Card } from "../../atoms/card";
import { Icon } from "../../atoms/icon";
interface IReviewSectionProps {
  title: string;
  description: string;
  reviews: {
    name: string;
    initials: string;
    rating: number;
    review: string;
    profilePictureUrl: string;
  }[];
}
export const ReviewSection = ({
  title,
  description,
  reviews
}: IReviewSectionProps): React.ReactNode => {
  const profileImgParam = "?q=75&fm=jpg&w=200&fit=max";
  return <section className="w-full py-10" data-sentry-component="ReviewSection" data-sentry-source-file="ReviewSection.tsx">
      <div className="container px-4 md:px-6">
        <div className="mx-auto flex flex-col items-center justify-center space-y-12">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="max-w-[700px] text-center text-4xl tracking-tight md:text-5xl">{title}</div>
            <p className="max-w-[560px] text-center text-gray-500 dark:text-gray-400 md:text-xl">{description}</p>
          </div>
          <div className="grid gap-8 sm:grid-cols-2">
            {reviews.map(review => <Card key={`${review.name}-${review.initials}`} className="rounded-lg border bg-paper p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
                <div className="flex items-center space-x-4">
                  <div className="shrink-0">
                    <Avatar className="size-12 rounded-full">
                      <AvatarImage src={review.profilePictureUrl + profileImgParam} />
                      <AvatarFallback>{review.initials}</AvatarFallback>
                    </Avatar>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">{review.name}</h3>
                    <div className="flex items-center space-x-1 text-yellow-500">
                      <Icon className="size-5 fill-yellow-500" name="star" />
                      <Icon className="size-5 fill-yellow-500" name="star" />
                      <Icon className="size-5 fill-yellow-500" name="star" />
                      <Icon className="size-5 fill-yellow-500" name="star" />
                      <Icon className="size-5 fill-yellow-500" name="star" />
                    </div>
                  </div>
                </div>
                <p className="mt-4 text-gray-500 dark:text-gray-400">{review.review}</p>
              </Card>)}
          </div>
        </div>
      </div>
    </section>;
};