// eslint-disable-next-line no-restricted-imports -- this is the lodash wrapper that may be allowed sometimes
import { fromPairs as _fromPairs } from "lodash";

/**
 * Converts an array of key-value pairs into an object.
 *
 * @param pairs The array of key-value pairs
 * @returns The object
 */
export function fromPairs<Key extends string, Value>(pairs: [Key, Value][]): Record<Key, Value> {
  return _fromPairs(pairs) as Record<Key, Value>;
}
