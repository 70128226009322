import React from "react";
export interface IActionListItem {
  leftIcon: React.ReactNode;
  text: string;
  rightIcon: React.ReactNode;
}
export const ActionListItem: React.FC<IActionListItem> = ({
  leftIcon,
  text,
  rightIcon
}) => {
  return <div className="flex items-center justify-between border" data-sentry-component="ActionListItem" data-sentry-source-file="ActionListItem.tsx">
      <div className="flex items-center space-x-2">
        <div className="shrink-0 bg-accent/70 p-2 text-brand">{leftIcon}</div>
        <span className="font-sans text-xs font-normal text-ink">{text}</span>
      </div>
      <div className="mr-2 shrink-0 text-accent-foreground">{rightIcon}</div>
    </div>;
};