import { z } from "zod";

export const ShapeColor = z.enum([
  "lilac",
  "purple",
  "pink",
  "red",
  "orange",
  "brown",
  "yellow",
  "lime",
  "sage",
  "green",
  "emerald",
  "teal",
  "blue",
  "neutral",
]);
export type IShapeColor = z.infer<typeof ShapeColor>;

export const SHAPE_COLOR_NAMES: IShapeColor[] = ShapeColor.options;
