import Image from "next/image";
import React from "react";
import { cn } from "../../../lib/utils";
import { Spinner } from "../../atoms/spinner";
export interface ILoader {
  text?: string | null;
  className?: string;
  variant?: "triangle" | "pyramid";
}
export const Loader: React.FC<ILoader> = ({
  className,
  text = "Loading...",
  variant = "triangle"
}) => <div className={cn("flex items-center gap-x-2 text-sm text-muted-foreground", className)} data-sentry-component="Loader" data-sentry-source-file="Loader.tsx">
    {variant === "pyramid" ? <Image alt="Loading" height={24} src="/spinning-triangle.gif" unoptimized={true} width={24} /> : <Spinner />}
    <span>{text}</span>
  </div>;