import { pickBy as _pickBy } from "lodash";

/**
 * Creates an object composed of properties that pass a boolean predicate test.
 * Use this for general filtering when you don't need type narrowing.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * const evenNumbers = pickBy(obj, (x) => x % 2 === 0);
 * // Result type: { b: 2 }
 *
 * @param obj The source object
 * @param predicate A boolean predicate function
 * @returns Returns a new object with only the properties that pass the predicate test
 */
export function pickBy<const Obj extends { [key: string | number | symbol]: unknown }>(
  obj: Obj,
  predicate: (x: Obj[keyof Obj], key: keyof Obj) => boolean,
): Obj {
  return _pickBy(obj, predicate) as Obj;
}
