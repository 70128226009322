import type React from "react";
import { useEffect, useState } from "react";
import { useClickAway } from "react-use";

/**
 * Detect current DOM focus within a given element.
 */
export function useFocusWithin(
  ref: React.RefObject<HTMLElement>,
  clickOutside: boolean = true,
): { hasFocus: boolean; resetFocus: () => void } {
  const [isFocused, setIsFocused] = useState<boolean | undefined>(false);

  useClickAway(ref, () => {
    if (clickOutside) {
      setIsFocused(false);
    }
  });

  /**
   * Check if an element has focus.
   */
  function hasFocusWithin(element: HTMLElement): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- extra safety
    if (element == null || document == null) return false;

    return element.contains(document.activeElement);
  }

  useEffect(() => {
    /**
     * Focus handler.
     */
    function handler(): void {
      if (!ref.current) return;
      setIsFocused(hasFocusWithin(ref.current));
    }

    // Bind focusin event listener.
    document.addEventListener("focusin", handler, false);

    return (): void => {
      // Dispose of event listener on unmount.
      document.removeEventListener("focusin", handler, false);
    };
  }, [ref, setIsFocused]);

  return {
    hasFocus: isFocused ?? false,
    resetFocus: () => setIsFocused(false),
  };
}
