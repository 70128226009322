// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { mapValues as _mapValues } from "lodash";

import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Creates an object with the same keys as `obj` and values generated by running each property
 * through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per property
 * @returns The new object with transformed values
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic util type
export function mapValues<const InObj extends IMatchesNeverArrayRecord<string, any>, NewValues>(
  obj: InObj | undefined | null,
  iteratee: (value: InObj[keyof InObj], key: keyof InObj, obj: InObj) => NewValues,
): Record<keyof InObj, NewValues> {
  return _mapValues(obj, iteratee) as Record<keyof InObj, NewValues>;
}
