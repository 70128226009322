import { z } from "zod";

import { BrandedWithGenerator } from "./idGenerationUtils";

export const ActionId = BrandedWithGenerator.from("ActionId", {
  prefix: "action",
  kind: undefined,
});

export const ActionVersionId = BrandedWithGenerator.from("ActionVersionId", {
  prefix: "actv",
  kind: "sortable",
});
/**
 * id identifying a entity type in the data model, stable across versions
 */
export const EntityTypeId = BrandedWithGenerator.from("EntityTypeId", {
  prefix: "et",
  kind: undefined,
});
export const EntityTypeVersionId = BrandedWithGenerator.from("EntityTypeVersionId", {
  prefix: "etv",
  kind: "sortable",
});
/**
 * id identifying a join between two types of entity data models, stable across versions
 */
export const RelationId = BrandedWithGenerator.from("RelationId", {
  prefix: "rel",
  kind: undefined,
});
export const RelationVersionId = BrandedWithGenerator.from("RelationVersionId", {
  prefix: "relv",
  kind: "sortable",
});
export const ColumnTypeId = BrandedWithGenerator.from("ColumnTypeId", {
  prefix: "colt",
  kind: "sortable",
});
export const ColumnTypeVersionId = BrandedWithGenerator.from("ColumnTypeVersionId", {
  prefix: "coltv",
  kind: "sortable",
});

export const CompositeTypeId = BrandedWithGenerator.from("CompositeTypeId", {
  prefix: "compt",
  kind: "sortable",
});
export const CompositeTypeVersionId = BrandedWithGenerator.from("CompositeTypeVersionId", {
  prefix: "comptv",
  kind: "sortable",
});

export const ModuleInstanceMetadataId = BrandedWithGenerator.from("ModuleInstanceMetadataId", {
  prefix: "modm",
  kind: "sortable",
});
export const ModuleInstanceMetadataVersionId = BrandedWithGenerator.from("ModuleInstanceMetadataVersionId", {
  prefix: "modmv",
  kind: "sortable",
});

export const ModuleInstanceId = BrandedWithGenerator.from("ModuleInstanceId", {
  prefix: "mod",
  kind: "sortable",
});
export const ModuleInstanceVersionId = BrandedWithGenerator.from("ModuleInstanceVersionId", {
  prefix: "modv",
  kind: "sortable",
});

export const StateMachineMetadataId = BrandedWithGenerator.from("StateMachineMetadataId", {
  prefix: "process",
  kind: "sortable",
});
export const StateMachineMetadataVersionId = BrandedWithGenerator.from("StateMachineMetadataVersionId", {
  prefix: "processv",
  kind: "sortable",
});

export const DataModelMetadataId = BrandedWithGenerator.from("DataModelMetadataId", {
  prefix: "dmm",
  kind: "sortable",
});
export const DataModelMetadataVersionId = BrandedWithGenerator.from("DataModelMetadataVersionId", {
  prefix: "dmmv",
  kind: "sortable",
});

function TableAndGroupedIds<
  TableName extends z.ZodLiteral<string>,
  Id extends z.ZodBranded<z.ZodString, string>,
  VersionId extends z.ZodBranded<z.ZodString, string>,
>(
  tableName: TableName,
  id: Id,
  versionId: VersionId,
): z.ZodObject<{ tableName: TableName; id: Id; versionId: VersionId }> {
  return z.object({
    tableName: tableName,
    id,
    versionId,
  });
}

function TableAndId<TableName extends z.ZodLiteral<string>, Id extends z.ZodBranded<z.ZodString, string>>(
  tableName: TableName,
  id: Id,
): z.ZodObject<{ tableName: TableName; id: Id }> {
  return z.object({
    tableName: tableName,
    id,
  });
}

export const ActionZodIds = TableAndGroupedIds(z.literal("builder.data_model_action"), ActionId, ActionVersionId);
export const EntityTypeZodIds = TableAndGroupedIds(
  z.literal("builder.data_model_entity_type"),
  EntityTypeId,
  EntityTypeVersionId,
);
export const EntityRelationZodIds = TableAndGroupedIds(
  z.literal("builder.data_model_entity_relation"),
  RelationId,
  RelationVersionId,
);
export const ColumnTypeZodIds = TableAndGroupedIds(
  z.literal("builder.data_model_column_type"),
  ColumnTypeId,
  ColumnTypeVersionId,
);
export const CompositeTypeZodIds = TableAndGroupedIds(
  z.literal("builder.data_model_composite_type"),
  CompositeTypeId,
  CompositeTypeVersionId,
);
export const ModuleInstanceMetadataZodIds = TableAndGroupedIds(
  z.literal("builder.module_instance_metadata"),
  ModuleInstanceMetadataId,
  ModuleInstanceMetadataVersionId,
);
export const ModuleInstanceZodIds = TableAndGroupedIds(
  z.literal("builder.module_instance"),
  ModuleInstanceId,
  ModuleInstanceVersionId,
);
export const StateMachineMetadataZodIds = TableAndGroupedIds(
  z.literal("builder.state_machine_metadata"),
  StateMachineMetadataId,
  StateMachineMetadataVersionId,
);
export const DataModelMetadataZodIds = TableAndGroupedIds(
  z.literal("builder.data_model_metadata"),
  DataModelMetadataId,
  DataModelMetadataVersionId,
);

export const VersionIdentifiedGroupedIdsAndTableName = z.union([
  ActionZodIds,
  EntityTypeZodIds,
  EntityRelationZodIds,
  ColumnTypeZodIds,
  CompositeTypeZodIds,
  ModuleInstanceMetadataZodIds,
  ModuleInstanceZodIds,
  StateMachineMetadataZodIds,
  DataModelMetadataZodIds,
]);

// Need some repetition because of zod, ideally we could map and drop fields from the single source of truth

export const VersionIdentifiedIdsAndTableName = z.union([
  TableAndId(z.literal("builder.data_model_action"), ActionId),
  TableAndId(z.literal("builder.data_model_entity_type"), EntityTypeId),
  TableAndId(z.literal("builder.data_model_entity_relation"), RelationId),
  TableAndId(z.literal("builder.data_model_column_type"), ColumnTypeId),
  TableAndId(z.literal("builder.data_model_composite_type"), CompositeTypeId),
  TableAndId(z.literal("builder.module_instance_metadata"), ModuleInstanceMetadataId),
  TableAndId(z.literal("builder.module_instance"), ModuleInstanceId),
  TableAndId(z.literal("builder.state_machine_metadata"), StateMachineMetadataId),
  TableAndId(z.literal("builder.data_model_metadata"), DataModelMetadataId),
]);

export const VersionIdentifiedTableNames = z.union([
  z.literal("builder.data_model_action"),
  z.literal("builder.data_model_entity_type"),
  z.literal("builder.data_model_entity_relation"),
  z.literal("builder.data_model_column_type"),
  z.literal("builder.data_model_composite_type"),
  z.literal("builder.module_instance_metadata"),
  z.literal("builder.module_instance"),
  z.literal("builder.state_machine_metadata"),
  z.literal("builder.data_model_metadata"),
]);

export const VersionIdentifiedConceptsIds = z.union([
  ActionId,
  EntityTypeId,
  RelationId,
  ColumnTypeId,
  CompositeTypeId,
  ModuleInstanceMetadataId,
  ModuleInstanceId,
  StateMachineMetadataId,
  DataModelMetadataId,
]);

export const VersionIdentifiedConceptsVersionIds = z.union([
  ActionVersionId,
  EntityTypeVersionId,
  RelationVersionId,
  ColumnTypeVersionId,
  CompositeTypeVersionId,
  ModuleInstanceMetadataVersionId,
  ModuleInstanceVersionId,
  StateMachineMetadataVersionId,
  DataModelMetadataVersionId,
]);

export type IVersionIdentifiedConceptsIdBrands =
  | "ActionId"
  | "EntityTypeId"
  | "RelationId"
  | "ColumnTypeId"
  | "CompositeTypeId"
  | "ModuleInstanceMetadataId"
  | "ModuleInstanceId"
  | "StateMachineMetadataId"
  | "DataModelMetadataId";

export type IVersionIdentifiedConceptsVersionIdBrands =
  | "ActionVersionId"
  | "EntityTypeVersionId"
  | "RelationVersionId"
  | "ColumnTypeVersionId"
  | "CompositeTypeVersionId"
  | "ModuleInstanceMetadataVersionId"
  | "ModuleInstanceVersionId"
  | "StateMachineMetadataVersionId"
  | "DataModelMetadataVersionId";

// ————————————————————————

export type IActionId = z.infer<typeof ActionId>;
export type IActionVersionId = z.infer<typeof ActionVersionId>;
export type IEntityTypeId = z.infer<typeof EntityTypeId>;
export type IEntityTypeVersionId = z.infer<typeof EntityTypeVersionId>;
export type IRelationId = z.infer<typeof RelationId>;
export type IRelationVersionId = z.infer<typeof RelationVersionId>;
export type IColumnTypeId = z.infer<typeof ColumnTypeId>;
export type IColumnTypeVersionId = z.infer<typeof ColumnTypeVersionId>;
export type ICompositeTypeId = z.infer<typeof CompositeTypeId>;
export type ICompositeTypeVersionId = z.infer<typeof CompositeTypeVersionId>;
export type IModuleInstanceMetadataId = z.infer<typeof ModuleInstanceMetadataId>;
export type IModuleInstanceMetadataVersionId = z.infer<typeof ModuleInstanceMetadataVersionId>;
export type IModuleInstanceId = z.infer<typeof ModuleInstanceId>;
export type IModuleInstanceVersionId = z.infer<typeof ModuleInstanceVersionId>;

export type IStateMachineMetadataId = z.infer<typeof StateMachineMetadataId>;
export type IStateMachineMetadataVersionId = z.infer<typeof StateMachineMetadataVersionId>;

export type IDataModelMetadataId = z.infer<typeof DataModelMetadataId>;
export type IDataModelMetadataVersionId = z.infer<typeof DataModelMetadataVersionId>;

export type IVersionIdentifiedGroupedIdsAndTableName = z.infer<typeof VersionIdentifiedGroupedIdsAndTableName>;
export type IVersionIdentifiedIdsAndTableName = z.infer<typeof VersionIdentifiedIdsAndTableName>;
export type IZodVersionIdentifiableIds = (typeof VersionIdentifiedGroupedIdsAndTableName.options)[number];

export type IVersionIdentifiedConceptsTableNames = IVersionIdentifiedGroupedIdsAndTableName["tableName"];
export type IVersionIdentifiedConceptsIds = IVersionIdentifiedGroupedIdsAndTableName["id"];
export type IVersionIdentifiedConceptsVersionIds = IVersionIdentifiedGroupedIdsAndTableName["versionId"];
