import * as React from "react";
import { cn } from "../../lib/utils";
import { Eyebrow } from "./eyebrow";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
export const LiveFieldIndicator: React.FC<{
  className?: string;
  type: "live" | "ai" | "lookup";
}> = ({
  className,
  type
}) => <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="LiveFieldIndicator" data-sentry-source-file="live-field-indicator.tsx">
    <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="live-field-indicator.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="live-field-indicator.tsx">
        <Eyebrow className={cn("leading-6", className)} variant="secondary" data-sentry-element="Eyebrow" data-sentry-source-file="live-field-indicator.tsx">
          {type}
        </Eyebrow>
      </TooltipTrigger>
      <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="live-field-indicator.tsx">
        <p>
          {((): string => {
          if (type === "ai") return "Computes automatically using AI";
          if (type === "lookup") return "Computes automatically using lookup";
          return "Computes automatically";
        })()}
        </p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>;