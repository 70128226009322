import assertNever from "assert-never";
import { DateTime } from "luxon";
import numeral from "numeral";

import type { IFormattedNumber } from "./api";

export const formatNumber = (format: IFormattedNumber): string => {
  switch (format.type) {
    case "integer": {
      return numeral(format.value).format("0,0");
    }

    case "float": {
      return numeral(format.value).format(format.formatting);
    }

    case "currency": {
      return numeral(format.value).format(format.formatting);
    }

    case "percentage": {
      return numeral(format.value).format(format.formatting);
    }

    case "date": {
      return (
        DateTime.fromJSDate(format.value)
          .setZone(format.timezone === undefined ? "UTC" : format.timezone)
          // TODO — this will need to change, US date format is not great and is a bad default
          .toLocaleString({ dateStyle: format.formatting }, { locale: "en-US" })
      );
    }

    case "timestamp": {
      return (
        DateTime.fromJSDate(format.value)
          .setZone(format.timezone === undefined ? "UTC" : format.timezone)
          // TODO — this will need to change, US date format is not great and is a bad default
          .toLocaleString({ timeStyle: format.formatting }, { locale: "en-US" })
      );
    }
  }

  assertNever(format);
};
