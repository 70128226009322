// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { flatMap as _flatMap } from "lodash";

import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Creates a flattened array of values by running each element in the object through
 * iteratee and flattening the mapped results. The iteratee is invoked with three
 * arguments: (value, key, object).
 *
 * Cannot operate on arrays. Use `Array.prototype.flatMap` instead.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns Returns the new flattened array
 */
export function flatMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic util type
  const Obj extends IMatchesNeverArrayRecord<string | number | symbol, any>,
  const Result,
>(
  obj: Obj | null | undefined,
  iteratee: (value: Obj[keyof Obj], key: keyof Obj, object: Obj) => Result | ReadonlyArray<Result>,
): Result[] {
  return _flatMap(obj, iteratee);
}
