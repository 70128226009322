interface ICreateQueryStringArgs {
  searchParams: URLSearchParams;
  name: string;
  value: string;
}

export function createQueryString({ searchParams, name, value }: ICreateQueryStringArgs): string {
  const params = new URLSearchParams(searchParams.toString());

  params.set(name, value);

  return params.toString();
}
