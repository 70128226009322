import { pickBy as _pickBy } from "lodash";

/**
 * Creates an object composed of properties that match a type guard predicate.
 * Use this when you want to narrow down the type of values in an object.
 *
 * @example
 * const obj = { a: 1, b: "hello", c: 42 };
 * const numbers = pickByGuarded(obj, (x): x is number => typeof x === "number");
 * // Result type: { a: number, c: number }
 *
 * @param obj The source object
 * @param predicate A type guard function that narrows the value type
 * @returns Returns a new object with only the properties that match the type guard
 */
export function pickByGuarded<
  const Obj extends { [key: string | number | symbol]: Wide },
  Wide,
  Narrow extends Obj[keyof Obj],
>(
  obj: Obj,
  predicate: (x: Obj[keyof Obj], key: keyof Obj) => x is Narrow,
): Pick<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]> {
  return _pickBy(obj, predicate) as Pick<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]>;
}
