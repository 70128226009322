/**
 * Groups the elements of an array by the keys from the function provided, discarding any values where the function returns `undefined`
 */
export function groupByNoUndefined<E, T extends Array<E>, K extends string = string>(
  arr: T,
  getKey: (value: T[number], index: number) => K | undefined,
): Record<K, T[number][]> {
  const res: Record<K, E[]> = {} as Record<K, E[]>;

  arr.forEach((value, index) => {
    const newKey = getKey(value, index);

    if (newKey == null) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- actually could be undefined
    if (res[newKey] == null) {
      res[newKey] = [] as E[];
    }

    res[newKey].push(value);
  });

  return res;
}
