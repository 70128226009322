// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { toPairs as _toPairs } from "lodash";

import type { IMatchesNeverArrayRecord, IToPairsObject } from "./types";

/**
 * Converts an object into an array of key-value pairs.
 *
 * Unlike the original `toPairs` function, this function retains the key type.
 *
 * @param obj The object to convert.
 * @returns An array of key-value pairs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic util type
export function toPairs<const Obj extends IMatchesNeverArrayRecord<string, any> | undefined>(
  obj: Obj,
): IToPairsObject<Obj> {
  return _toPairs(obj) as IToPairsObject<Obj>;
}
