import { has } from "lodash";

import { keys } from "../tsDash";

export const richKeysDifference = <
  const ObjA extends Record<string, unknown>,
  const ObjB extends Record<string, unknown>,
>(args: {
  objectA: ObjA;
  objectB: ObjB;
}): {
  inANotB: (keyof ObjA)[];
  inBNotA: (keyof ObjB)[];
} => {
  const { objectA, objectB } = args;

  const inANotB: (keyof ObjA)[] = [];
  const inBNotA: (keyof ObjB)[] = [];

  keys(objectA).forEach((key) => {
    if (has(objectA, key) && !has(objectB, key)) {
      inANotB.push(key);
    }
  });

  keys(objectB).forEach((key) => {
    if (has(objectB, key) && !has(objectA, key)) {
      inBNotA.push(key);
    }
  });

  return {
    inANotB,
    inBNotA,
  };
};
