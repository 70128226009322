/**
 * Creates an object from an array with keys from the function provided, discarding any values where the function returns `undefined`
 */
export function keyByNoUndefined<const OutKeys extends string, const Value>(
  arr: Array<Value>,
  getKey: (value: Value, index: number) => OutKeys | undefined,
): Record<OutKeys, Value> {
  const res: Record<OutKeys, Value> = {} as Record<OutKeys, Value>;

  arr.forEach((value, index) => {
    const newKey = getKey(value, index);

    if (newKey == null) {
      return;
    }
    res[newKey] = value;
  });

  return res;
}
