import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import type { ClassValue } from "clsx";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
const buttonVariants = cva("relative inline-flex select-none items-center justify-center gap-x-1.5 rounded-lg border border-brand text-base font-normal uppercase tracking-wider text-brand ring-offset-paper transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:pointer-events-none disabled:border-accent-foreground disabled:bg-muted-background disabled:text-accent-foreground disabled:shadow-none", {
  variants: {
    variant: {
      primary: "bg-brand-light text-primary-foreground shadow-[inset_0_-4px_0_0_hsl(var(--brand-blue))] hover:brightness-110 active:shadow-[inset_0_-2px_0_0_hsl(var(--accent-fg))]",
      secondary: "bg-paper shadow-[inset_0_-4px_0_0_hsl(var(--secondary-bg))] hover:after:absolute hover:after:inset-0 hover:after:size-full hover:after:bg-accent-background active:shadow-[inset_0_-2px_0_0_hsl(var(--secondary-bg))]",
      ghost: "border-transparent bg-transparent hover:bg-accent-background active:bg-muted-background disabled:border-transparent disabled:bg-transparent",
      link: "gap-x-1 border-none bg-transparent !px-0 normal-case tracking-normal underline decoration-secondary-background underline-offset-2 hover:decoration-brand disabled:border-transparent disabled:bg-transparent disabled:text-accent-foreground disabled:no-underline"
    },
    size: {
      xs: "h-6 px-2 text-xs",
      sm: "h-8 px-3 text-sm",
      md: "h-11 px-4 text-base",
      lg: "h-14 px-5 text-lg"
    }
  },
  compoundVariants: [{
    variant: "primary",
    size: ["sm", "xs"],
    className: "shadow-[inset_0_-3px_0_0_hsl(var(--brand-blue))]"
  }, {
    variant: "secondary",
    size: ["sm", "xs"],
    className: "shadow-[inset_0_-3px_0_0_hsl(var(--secondary-bg))]"
  }],
  defaultVariants: {
    variant: "primary",
    size: "sm"
  }
});
export interface IBaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
  isLoading?: boolean;
}
export type IButtonPropsWithoutChildren = {
  children?: never;
  title: string;
} | {
  children?: never;
  iconLeft: IIconNames;
};
export type IButtonPropsWithChildren = {
  children: React.ReactNode;
};
export type IButtonProps = IBaseButtonProps & (IButtonPropsWithoutChildren | IButtonPropsWithChildren);
function getSquareStyles({
  children,
  iconLeft,
  iconRight,
  size
}: {
  children: IBaseButtonProps["children"];
  iconLeft: IBaseButtonProps["iconLeft"];
  iconRight: IBaseButtonProps["iconRight"];
  size: IBaseButtonProps["size"];
}): ClassValue {
  if (children == null && (iconLeft != null || iconRight != null)) {
    switch (size) {
      case "xs":
        {
          return "w-6 px-1";
        }
      case "sm":
        {
          return "size-8 px-1";
        }
      case "md":
        {
          return "size-11 px-1";
        }
      default:
        {
          return "size-14 px-1";
        }
    }
  } else {
    return "";
  }
}
const getGapSize = (size: IBaseButtonProps["size"]): string => {
  switch (size) {
    case "lg":
      {
        return "gap-x-2";
      }
    case "sm":
      {
        return "gap-x-1";
      }
    default:
      {
        return "gap-x-1.5";
      }
  }
};
const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  children,
  iconLeft,
  iconRight,
  isLoading,
  ...props
}, ref) => {
  const ButtonComponent = asChild ? Slot : "button";
  const getIconSize = (buttonSize: typeof size): string => {
    switch (buttonSize) {
      case "xs":
        {
          return "size-3";
        }
      case "sm":
        {
          return "size-4";
        }
      default:
        {
          return "size-5";
        }
    }
  };
  const iconSize = getIconSize(size);
  const iconClass = cn(iconSize, "shrink-0");
  const squareStyles = getSquareStyles({
    children,
    iconLeft,
    iconRight,
    size
  });
  const content = <span className={cn("inline-flex items-center justify-center truncate leading-[normal]", variant !== "ghost" && variant !== "link" && "group-active:translate-y-px", getGapSize(size))}>
        {(iconLeft != null || children != null) && <>
            {iconLeft != null && <Icon className={iconClass} name={iconLeft} />}
            {children != null && <span className="truncate">{children}</span>}
          </>}
        {iconRight != null && <Icon className={iconClass} name={iconRight} />}
      </span>;
  const buttonComponent = <ButtonComponent ref={ref} className={cn(buttonVariants({
    variant,
    size,
    className
  }), squareStyles, "group", "overflow-hidden")} disabled={props.disabled === true || isLoading} {...props}>
        {content}
        {isLoading === true && variant !== "link" && <div className="absolute inset-x-0 bottom-0 h-0.5">
            <div className={cn("h-full animate-loading-bar bg-brand")} />
          </div>}
      </ButtonComponent>;
  const title = props.title;
  if (title == null) {
    return buttonComponent;
  }
  return <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>{buttonComponent}</TooltipTrigger>
          <TooltipContent className="bg-black text-white">{title}</TooltipContent>
        </Tooltip>
      </TooltipProvider>;
});
Button.displayName = "Button";
export { Button, buttonVariants };