"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Spinner } from "./spinner";
const Select = SelectPrimitive.Root;
const SelectValue = SelectPrimitive.Value;
const SelectGroup = SelectPrimitive.Group;
interface ISelectIcon {
  icon?: IIconNames;
}
const selectTriggerVariants = cva("flex items-center justify-between overflow-hidden whitespace-nowrap", {
  variants: {
    size: {
      default: inputVariants({
        size: "default"
      }),
      small: inputVariants({
        size: "small"
      })
    },
    variant: {
      default: "",
      muted: "border-none bg-transparent text-muted-foreground hover:bg-accent-background"
    }
  }
});
const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & ISelectIcon & {
  small?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
  placeholder?: string;
  variant?: "default" | "muted";
}>(({
  className,
  children,
  small,
  isLoading,
  onClear,
  placeholder,
  variant = "default",
  ...props
}, ref) => {
  const handlePointerDown = React.useCallback((e: React.PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClear?.();
  }, [onClear]);
  return <SelectPrimitive.Trigger ref={ref} className={cn(selectTriggerVariants({
    variant,
    size: small === true ? "small" : "default"
  }), className)} slot="div" {...props}>
      {children}
      {children == null && placeholder != null ? <SelectPrimitive.Value placeholder={placeholder} /> : null}
      {isLoading === true ? <Spinner className="ml-2" /> : <SelectPrimitive.Icon asChild>
          <div className="flex items-center gap-2">
            {onClear ? <span onPointerDown={handlePointerDown}>
                <Icon className="size-3" name="x" />
              </span> : null}
            <Icon name={props.icon ?? "chevron-down"} />
          </div>
        </SelectPrimitive.Icon>}
    </SelectPrimitive.Trigger>;
});
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
interface ISelectContentProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> {
  contentClassName?: string;
}
const SelectContent = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, ISelectContentProps>(({
  className,
  children,
  position = "popper",
  contentClassName,
  ...props
}, ref) => <SelectPrimitive.Portal>
      <SelectPrimitive.Content ref={ref} className={cn("relative z-50 min-w-32 overflow-hidden rounded-md border bg-popover text-popover-foreground shadow data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", position === "popper" && "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1", className)} position={position} {...props}>
        <SelectPrimitive.Viewport className={cn("p-1", position === "popper" && "h-[var(--radix-select-trigger-height)] max-h-60 w-full min-w-[var(--radix-select-trigger-width)] overflow-y-auto", contentClassName)}>
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>);
SelectContent.displayName = SelectPrimitive.Content.displayName;
const SelectLabel = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.Label ref={ref} className={cn("py-1.5 pl-6 pr-2 text-xs font-medium uppercase text-muted-foreground", className)} {...props} />);
SelectLabel.displayName = SelectPrimitive.Label.displayName;
const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
  small?: boolean;
}>(({
  className,
  children,
  small = false,
  ...props
}, ref) => <SelectPrimitive.Item ref={ref} className={cn("relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-6 pr-2 outline-none focus:bg-accent focus:text-ink data-[disabled]:pointer-events-none data-[disabled]:opacity-50", small ? "text-base" : "text-lg", className)} {...props}>
    <span className="absolute left-2 flex size-2.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Icon className="size-3" name="check" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>);
SelectItem.displayName = SelectPrimitive.Item.displayName;
const SelectSeparator = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.Separator ref={ref} className={cn("-mx-1 my-1 h-px bg-muted", className)} {...props} />);
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
export { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, selectTriggerVariants, SelectValue };