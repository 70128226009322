import { Button } from "../../atoms/button";
import { Icon, type IIconNames } from "../../atoms/icon";
interface IFeatureSectionProps {
  title: string;
  description: string;
  features: {
    icon: IIconNames;
    title: string;
    description: string;
  }[];
}
export const FeatureSection = ({
  title,
  description,
  features
}: IFeatureSectionProps): React.ReactNode => {
  return <section className="w-full px-4 py-24" data-sentry-component="FeatureSection" data-sentry-source-file="FeatureSection.tsx">
      <div className="container flex flex-col space-y-16 rounded-xl bg-muted-background px-4 py-24 md:px-6">
        <div className="grid gap-12 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_600px]">
          <div className="flex flex-col items-start justify-start space-y-6">
            <div className="flex flex-col items-start justify-center space-y-4 lg:ml-12">
              <div className="inline-block rounded-md bg-gray-200 px-3 py-1 text-sm dark:bg-gray-800">Key Features</div>
              <div className="max-w-[600px] text-4xl tracking-tight md:text-5xl">{title}</div>
              <p className="max-w-[420px] text-lg text-gray-500 md:text-xl">{description}</p>
            </div>
          </div>
          <div className="grid grid-rows-3 items-center gap-12">
            {features.map(feature => <div key={feature.title} className="grid gap-4 sm:w-full md:w-8/12">
                <div className="flex items-center gap-3">
                  <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded-full bg-primary-background text-gray-50 dark:bg-muted-background dark:text-gray-900">
                    <Icon className="size-4" name={feature.icon} />
                  </div>
                  <div className="text-2xl">{feature.title}</div>
                </div>
                <p className="text-lg text-gray-500 dark:text-gray-400 md:text-xl">{feature.description}</p>
                <Button className="w-fit" iconRight="arrow-right" variant="secondary">
                  Get started
                </Button>
              </div>)}
          </div>
        </div>
      </div>
    </section>;
};