import assertNever from "assert-never";
import Link from "next/link";
import type { IRoute } from "../../lib/api";
import type { IShapeColor } from "../../lib/shapeColor";
import type { IShapeName } from "../../lib/shapeName";
import { cn } from "../../lib/utils";
import type { IBadgeProps } from "./badge";
import { Badge } from "./badge";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "./dropdown-menu";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { ShapeColorIcon } from "./shape-color-icon";
import { Skeleton } from "./skeleton";
import { Spinner } from "./spinner";
export interface IAdvancedDropdownMenuContent {
  groups: IAdvancedDropdownMenuGroup[];
}
export type IAdvancedDropdownMenuItem = IAdvancedDropdownMenuButtonItem | IAdvancedDropdownMenuLinkItem | IAdvancedDropdownMenuSubmenuItem | IAdvancedDropdownMenuSkeletonItem;
export type IAdvancedDropdownMenuIcon = {
  type: "icon";
  value: IIconNames;
} | {
  type: "shape";
  value: IShapeName;
  color: IShapeColor;
};
interface IActionableItemCommon {
  id: string;
  label: string;
  icon?: IAdvancedDropdownMenuIcon;
  badge?: {
    label: string;
    variant?: IBadgeProps["colorVariant"];
  };
  subText?: {
    text: string;
    variant?: "error" | "default";
  };
}
export interface IAdvancedDropdownMenuButtonItem extends IActionableItemCommon {
  type: "button";
  disabled?: boolean;
  loading?: boolean;
  onSelect?: (e: Event | React.MouseEvent) => void;
}
export interface IAdvancedDropdownMenuLinkItem extends IActionableItemCommon {
  type: "link";
  disabled?: boolean;
  href: {
    type: "internal";
    route: IRoute;
  } | {
    type: "external";
    url: string;
  };
}
export interface IAdvancedDropdownMenuSubmenuItem extends IActionableItemCommon {
  type: "submenu";
  subItems: IAdvancedDropdownMenuContent;
  onSelect?: undefined;
}
export interface IAdvancedDropdownMenuSkeletonItem {
  type: "skeleton";
  id: string;
}
export interface IAdvancedDropdownMenuGroup {
  groupId: string;
  title?: string;
  items: IAdvancedDropdownMenuItem[];
}
export interface IAdvancedDropdownMenu {
  children: React.ReactNode;
  content: IAdvancedDropdownMenuContent;
}
export const AdvancedDropdownMenu: React.FC<IAdvancedDropdownMenu> = ({
  children,
  content
}) => {
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="AdvancedDropdownMenu" data-sentry-source-file="advanced-dropdown-menu.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="advanced-dropdown-menu.tsx">{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[400px] overflow-y-auto" data-sentry-element="DropdownMenuContent" data-sentry-source-file="advanced-dropdown-menu.tsx">
        {content.groups.map((group, groupIndex) => {
        const anyItemHasIcon = group.items.some(item => item.type !== "skeleton" && item.icon != null);
        return <DropdownMenuGroup key={group.groupId}>
              {groupIndex !== 0 && <DropdownMenuSeparator />}
              {group.title != null ? <DropdownMenuLabel>{group.title}</DropdownMenuLabel> : null}
              {group.items.map(item => <AdvancedDropdownMenuItemComponent key={item.id} enforceIconAlignment={anyItemHasIcon} item={item} />)}
            </DropdownMenuGroup>;
      })}
      </DropdownMenuContent>
    </DropdownMenu>;
};
export const AdvancedDropdownMenuItemComponent: React.FC<{
  item: IAdvancedDropdownMenuItem;
  /**
   * If true, will enforce some empty space if there is no icon so that it can be aligned with other items with an icon
   */
  enforceIconAlignment: boolean;
}> = ({
  item,
  enforceIconAlignment
}) => {
  if (item.type === "skeleton") {
    return <DropdownMenuItem>
        <Skeleton className="h-4 w-[100px]" />
      </DropdownMenuItem>;
  }
  const {
    label,
    icon,
    badge
  } = item;
  const isLoading = item.type === "button" ? item.loading : undefined;
  const rowContent = <>
      {icon?.type === "shape" && <ShapeColorIcon color={icon.color} shape={icon.value} size="sm" />}
      {icon?.type === "icon" && <Icon name={icon.value} />}
      {enforceIconAlignment && icon == null ? <div className="size-4 shrink-0" /> : null}
      <span className="grow">{label}</span>
      {badge != null && <Badge colorVariant={badge.variant}>{badge.label}</Badge>}
      {isLoading === true && <Spinner />}
    </>;
  let content: JSX.Element;
  if (item.subText != null && item.subText.text !== "") {
    content = <div className="max-w-72 gap-y-0.5">
        <div className="flex w-full items-center justify-between gap-x-2">{rowContent}</div>
        <div className="flex w-full items-start gap-x-2">
          {/* To align with the title and leave empty space for the icon */}
          {icon != null && <div className="size-4 shrink-0" />}
          <span className={cn("shrink grow text-sm", item.subText.variant === "error" ? "text-error" : "text-muted-foreground")}>
            {item.subText.text}
          </span>
          {/* To align with the title and leave empty space for the spinner */}
          {isLoading === true && <div className="size-4 shrink-0" />}
        </div>
      </div>;
  } else {
    content = rowContent;
  }
  if (item.type === "submenu") {
    return <DropdownMenuSub>
        <DropdownMenuSubTrigger className="flex w-full items-center justify-between gap-x-2">
          {content}
        </DropdownMenuSubTrigger>
        <DropdownMenuSubContent className="max-h-[350px] overflow-y-auto">
          {item.subItems.groups.map((group, groupIndex) => {
          const anyItemHasIcon = group.items.some(subItem => subItem.type !== "skeleton" && subItem.icon != null);
          return <DropdownMenuGroup key={group.groupId}>
                {groupIndex !== 0 && <DropdownMenuSeparator />}
                {group.title != null ? <DropdownMenuLabel>{group.title}</DropdownMenuLabel> : null}
                {group.items.map(subItem => <AdvancedDropdownMenuItemComponent key={subItem.id} enforceIconAlignment={anyItemHasIcon} item={subItem} />)}
              </DropdownMenuGroup>;
        })}
        </DropdownMenuSubContent>
      </DropdownMenuSub>;
  }
  if (item.type === "link") {
    switch (item.href.type) {
      case "internal":
        {
          return <DropdownMenuItem asChild disabled={item.disabled}>
            <Link className="flex items-center gap-x-2 no-underline" href={item.href.route}>
              {content}
            </Link>
          </DropdownMenuItem>;
        }
      case "external":
        {
          return <DropdownMenuItem asChild disabled={item.disabled}>
            <a className="flex items-center gap-x-2 no-underline" href={item.href.url}>
              {content}
            </a>
          </DropdownMenuItem>;
        }
      default:
        {
          assertNever(item.href);
        }
    }
  }
  const handleSelect = item.onSelect;
  const isDisabledButton = item.disabled;
  const disabled = isDisabledButton == null && isLoading == null ? undefined : isDisabledButton === true || isLoading === true;
  return <DropdownMenuItem className="gap-x-2" disabled={disabled} onSelect={handleSelect} data-sentry-element="DropdownMenuItem" data-sentry-component="AdvancedDropdownMenuItemComponent" data-sentry-source-file="advanced-dropdown-menu.tsx">
      {content}
    </DropdownMenuItem>;
};