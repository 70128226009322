// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { forEach as _forEach } from "lodash";

import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Iterates over an object, calling the iteratee for each key-value pair.
 *
 * Cannot operate on arrays. Use `Array.prototype.forEach` instead.
 */
export function forEach<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic util type
  const Obj extends IMatchesNeverArrayRecord<string | number | symbol, any>,
  Iteratee extends (value: Obj[keyof Obj], key: keyof Obj, obj: Obj) => void,
>(obj: Obj | undefined | null, iteratee: Iteratee extends Promise<infer _> ? never : Iteratee): void {
  _forEach(obj, iteratee);
}
