import { fromPairs } from "./fromPairs";
import { toPairs } from "./toPairs";

/**
 * Asynchronously filters an object's values based on a predicate function.
 *
 * @param obj The source object
 * @param predicate The async function invoked per property.
 * @returns A new object with properties that passed the predicate test.
 */
export async function pickByAsync<Key extends string, Value>(
  obj: Record<Key, Value>,
  predicate: (value: Value, key: Key, obj: Record<Key, Value>) => Promise<boolean>,
): Promise<Record<Key, Value>> {
  const pairs = await Promise.all(
    toPairs(obj).map(async ([key, value]) => {
      const keep = await predicate(value, key, obj);

      return keep ? ([key, value] as [Key, Value]) : null;
    }),
  );

  const filteredEntries = pairs.filter((entry): entry is [Key, Value] => entry !== null);

  return fromPairs(filteredEntries);
}
