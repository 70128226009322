import React from "react";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
export interface ITableSkeleton {
  className?: string;
  numberCols: number;
  numberRows: number;
  density: "compact" | "default" | "airy";
}

// eslint-disable-next-line max-params -- not semantic argument names
const randomLookingLcg = (row: number, col: number, a = 11, c = 23, m = 5): number => {
  if (row % m === 0) {
    return (col * a + c) % m / m;
  }
  if (col % m === 0) {
    return (row * a + c) % m / m;
  }
  return (row * col * a + c) % m / m;
};
const DEFAULT_HEADER_HEIGHT = 56; // TODO @sspringer these should come from the data table context
const DEFAULT_ROW_HEIGHT = 42; // TODO @sspringer these should come from the data table context

export const TableSkeleton: React.FC<ITableSkeleton> = ({
  className,
  numberCols,
  numberRows,
  density
}) => {
  const densityClass = {
    compact: "p-1",
    default: "p-2",
    airy: "p-3"
  }[density];
  return <div className={cn("overflow-auto rounded border border-border bg-paper outline-none @container", className)} data-sentry-component="TableSkeleton" data-sentry-source-file="table-skeleton.tsx">
      <div className="sticky top-0 z-20 w-fit">
        <div className="flex items-center border-b border-border bg-paper-alt" style={{
        height: DEFAULT_HEADER_HEIGHT
      }}>
          {Array.from({
          length: numberCols
        }).map((_, index) =>
        // eslint-disable-next-line react/no-array-index-key -- actually index based content for skeleton
        <div key={index} className={cn(densityClass, "flex w-[200px] shrink-0 items-center")}>
              <Skeleton className="h-5" style={{
            width: 50 + 100 * randomLookingLcg(20, index + 1)
          }} />
            </div>)}
        </div>
      </div>
      <div className="relative w-fit" style={{
      height: numberRows * DEFAULT_ROW_HEIGHT
    }}>
        {Array.from({
        length: numberRows
      }).map((_, rowIndex) => <div
      // eslint-disable-next-line react/no-array-index-key -- actually index based content for skeleton
      key={rowIndex} className="flex border-b border-border" style={{
        height: DEFAULT_ROW_HEIGHT
      }}>
            {Array.from({
          length: numberCols
        }).map((__, colIndex) =>
        // eslint-disable-next-line react/no-array-index-key -- actually index based content for skeleton
        <div key={colIndex} className={cn(densityClass, "flex w-[200px] shrink-0 items-center")}>
                <Skeleton className="h-4" style={{
            width: 50 + 100 * randomLookingLcg(rowIndex + 1, colIndex + 1)
          }} />
              </div>)}
          </div>)}
      </div>
    </div>;
};