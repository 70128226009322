import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Skeleton } from "./skeleton";
import type { ITooltipContentProps } from "./tooltip";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
const menuItemVariants = cva("inline-flex h-7 max-h-7 min-h-7 w-full items-center justify-start gap-x-2 rounded border border-transparent px-1.5 text-left font-normal tracking-normal text-ink ring-offset-paper transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      ghost: "border bg-transparent hover:border-border hover:bg-accent-background active:bg-muted-background disabled:bg-muted-background disabled:opacity-70",
      selected: "border border-border bg-accent-background hover:bg-muted-background active:bg-accent-background disabled:bg-accent-background disabled:opacity-70",
      danger: "border bg-transparent hover:border-border hover:bg-accent-background hover:text-red-600 active:bg-muted-background disabled:bg-muted-background disabled:opacity-70"
    },
    size: {
      md: "text-base",
      lg: "h-9 max-h-9 min-h-9 text-lg"
    }
  },
  defaultVariants: {
    variant: "ghost",
    size: "md"
  }
});
interface IBaseMenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof menuItemVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  isDraggable?: boolean;
  leftElements?: React.ReactNode[];
  rightElements?: React.ReactNode[];
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
}
type IMenuItemPropsWithoutChildren = {
  children?: never;
  title: string;
} | {
  children?: never;
  iconLeft: IIconNames;
};
type IMenuItemPropsWithChildren = {
  children: React.ReactNode;
};
export type IMenuItemProps = IBaseMenuItemProps & (IMenuItemPropsWithoutChildren | IMenuItemPropsWithChildren) & {
  titleTooltipProps?: ITooltipContentProps;
};
const MenuItem = React.forwardRef<HTMLButtonElement, IMenuItemProps>(({
  className,
  variant,
  size,
  asChild = false,
  children,
  iconLeft,
  isLoading,
  iconRight,
  leftElements,
  rightElements,
  titleTooltipProps,
  ...props
}, ref) => {
  const MenuItemComponent = asChild ? Slot : "button";
  if (isLoading === true) {
    return <Skeleton className={cn("flex h-7 w-full grow", className)} />;
  }
  const menuItemComponent = <MenuItemComponent ref={ref} className={cn(menuItemVariants({
    variant,
    size,
    className
  }))} {...props}>
        <div className="group flex w-full grow flex-row place-content-between">
          <div className="flex min-w-0 grow items-center justify-start gap-x-2 leading-[normal]">
            {leftElements}
            {iconLeft != null ? <Icon className="size-3 shrink-0" name={iconLeft} /> : null}
            {children != null && <span className="overflow-hidden text-ellipsis text-nowrap">{children}</span>}
          </div>
          <div className="flex shrink-0 items-center justify-start gap-x-0.5">
            <div className={cn("flex grow items-center justify-start")}>{rightElements}</div>
            {iconRight != null ? <Icon className="size-3" name={iconRight} /> : null}
          </div>
        </div>
      </MenuItemComponent>;
  const title = props.title;
  if (title == null) {
    return menuItemComponent;
  }
  return <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>{menuItemComponent}</TooltipTrigger>
          <TooltipContent {...titleTooltipProps}>{title}</TooltipContent>
        </Tooltip>
      </TooltipProvider>;
});
MenuItem.displayName = "MenuItem";
export { MenuItem, menuItemVariants };