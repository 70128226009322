// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { keys as _keys } from "lodash";

import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Returns an array of the object's own enumerable property names, typed as keys of the object.
 *
 * @param obj The object to get keys from
 * @returns An array of the object's keys with proper typing
 */
export function keys<const Obj extends IMatchesNeverArrayRecord<string, unknown>>(obj: Obj): (keyof Obj)[] {
  return _keys(obj) as (keyof Obj)[];
}
