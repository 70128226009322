import baseX from "base-x";
import * as crypto from "crypto";
import KSUID from "ksuid";
import { customAlphabet } from "nanoid";
import { z } from "zod";

const CUSTOM_ALPHABET = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

const nanoid = customAlphabet(CUSTOM_ALPHABET);
const base62 = baseX(CUSTOM_ALPHABET);

export class BrandedWithGenerator<B extends string> extends z.ZodBranded<z.ZodString, B> {
  public __brand: B;

  public readonly prefix: string;
  private kind?: "lowCardinalityScoped" | "sortable";

  constructor(brand: B, opts: { prefix: string; kind?: "lowCardinalityScoped" | "sortable" }) {
    const brandedString = z.string().brand(brand);

    super(brandedString._def);

    this.prefix = opts.prefix;
    this.kind = opts.kind;
    this.__brand = brand;
  }

  static from<B extends string>(
    brand: B,
    opts: { prefix: string; kind?: "lowCardinalityScoped" | "sortable" },
  ): BrandedWithGenerator<B> {
    return new BrandedWithGenerator(brand, opts);
  }

  generate(): z.infer<z.ZodBranded<z.ZodString, B>> {
    return `${this.prefix}${this.prefix !== "" ? "_" : ""}${
      this.kind === "sortable" ? KSUID.randomSync().string : nanoid(this.kind === "lowCardinalityScoped" ? 12 : 18)
    }` as string & z.BRAND<B>;
  }

  generateFromInput(input: string): z.infer<z.ZodBranded<z.ZodString, B>> {
    if (this.kind === "sortable") {
      throw new Error("Cannot generate sortable id from input");
    }

    const hash = crypto.createHash("sha256");

    hash.update(input);
    const hashBytes = hash.digest();

    const hashString = base62.encode(hashBytes);

    return `${this.prefix}${this.prefix !== "" ? "_" : ""}${hashString}` as string & z.BRAND<B>;
  }
}
