import * as React from "react";
import type { IBadgeProps } from "./badge";
import { Badge } from "./badge";
export type IBadgeWithDotProps = Omit<IBadgeProps, "iconLeft" | "iconRight" | "children"> & {
  label: string | null;
};
const BadgeWithDot = React.forwardRef<HTMLDivElement, IBadgeWithDotProps>(({
  label,
  ...badgeProps
}, ref) => <Badge {...badgeProps} ref={ref}>
    <div className="flex items-center gap-x-1.5 overflow-hidden">
      <div className="size-[7px] shrink-0 rounded-full bg-current p-0" />
      <span className="truncate">{label}</span>
    </div>
  </Badge>);
export default BadgeWithDot;
BadgeWithDot.displayName = "BadgeWithDot";
export { BadgeWithDot };