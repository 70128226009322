import * as React from "react";
import { cn } from "../../lib/utils";
import { Eyebrow } from "./eyebrow";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
export const AutofillIndicator: React.FC<{
  className?: string;
  type: "logic" | "ai";
}> = ({
  className,
  type
}) => <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="AutofillIndicator" data-sentry-source-file="autofill-indicator.tsx">
    <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="autofill-indicator.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="autofill-indicator.tsx">
        <Eyebrow className={cn("leading-6", className)} variant="secondary" data-sentry-element="Eyebrow" data-sentry-source-file="autofill-indicator.tsx">
          {type === "ai" ? "Autofilled by AI" : "Autofilled"}
        </Eyebrow>
      </TooltipTrigger>
      <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="autofill-indicator.tsx">
        <p>{type === "ai" ? "This field is autofilled by AI" : "This field is autofilled by a formula"}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>;