import { motion } from "framer-motion";
import React, { useCallback, useMemo } from "react";
import type { ControllerRenderProps } from "react-hook-form";
import { useForm } from "react-hook-form";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Form, FormControl, FormField, FormItem } from "./form";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Input } from "./input";
export type IInputWithSubmitProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onSubmit"> & {
  containerClassName?: string;
  placeholder: string;
  className?: string;
  iconLeft?: IIconNames;
  isLoading?: boolean;
  getErrorStr?: (value: string) => string | undefined;
  onSubmit: (value: string) => Promise<void>;
  submitIcon?: IIconNames;
  small?: boolean;
};
const InputWithSubmit = React.forwardRef<HTMLInputElement, IInputWithSubmitProps>(({
  isLoading,
  getErrorStr,
  onSubmit,
  submitIcon,
  containerClassName,
  small,
  ...props
}, ref) => {
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const form = useForm({
    defaultValues: {
      value: ""
    }
  });
  const renderInput = useCallback((loading: boolean) =>
  // eslint-disable-next-line react/display-name -- inner no need
  ({
    field
  }: {
    field: ControllerRenderProps<{
      value: string;
    }>;
  }): JSX.Element => <FormItem>
            <FormControl>
              <Input autoFocus autoComplete="off" className="!no-focus-outline" {...props} {...field} ref={ref} disabled={loading} small={small} value={field.value}
      // eslint-disable-next-line react/jsx-handler-names -- functional
      onChange={field.onChange} />
            </FormControl>
          </FormItem>, [ref, small, props]);
  const currentValue = form.watch("value");
  const maybeError = useMemo(() => getErrorStr && getErrorStr(currentValue), [currentValue, getErrorStr]);
  const innerOnSubmit = useCallback((data: {
    value: string;
  }) => {
    if (getErrorStr && getErrorStr(data.value) != null) {
      return;
    }
    setIsSubmiting(true);
    void onSubmit(data.value).then(() => {
      form.setValue("value", "");
      setIsSubmiting(false);
    }).catch(() => {
      setIsSubmiting(false);
    });
  }, [setIsSubmiting, onSubmit, form, getErrorStr]);
  const innerIsLoading = isLoading === true || isSubmiting;
  return <Form {...form}>
        <form className={cn("relative", containerClassName)}
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- functional
    onSubmit={form.handleSubmit(innerOnSubmit)}>
          <FormField control={form.control} name="value" render={renderInput(innerIsLoading)} />
          <Button className="absolute right-1 top-1" data-dd-action-name="Submit" disabled={innerIsLoading || maybeError != null} iconLeft={submitIcon ?? "chevron-right"} isLoading={innerIsLoading} size={small === true ? "xs" : "md"} type="submit" variant="ghost" />
          {maybeError != null && maybeError !== "" && <div className="flex h-6 items-center justify-between px-1.5 pt-2.5 text-sm text-muted-foreground">
              <motion.div animate={{
          opacity: 1
        }} className="flex w-full justify-between" exit={{
          opacity: 0
        }} initial={{
          opacity: 0
        }}>
                <div className="flex items-center space-x-2">
                  <Icon className="text-red-500" name="shield-x" />
                  <span>{maybeError}</span>
                </div>
              </motion.div>
            </div>}
        </form>
      </Form>;
});
InputWithSubmit.displayName = "InputWithSubmit";
export { InputWithSubmit };