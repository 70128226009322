import { CollapsibleContent } from "@radix-ui/react-collapsible";
import type { PropsWithChildren } from "react";
import React, { useCallback } from "react";
import { cn } from "../../../lib/utils";
import { Collapsible, CollapsibleTrigger } from "../../atoms/collapsible";
import { Icon } from "../../atoms/icon";
export interface ICollapsibleActionListItem extends PropsWithChildren {
  leftIcon: React.ReactNode;
  text: string;
  open: boolean;
  disabled?: boolean;
  onOpenChange?: (open: boolean) => void;
}
export const CollapsibleActionListItem: React.FC<ICollapsibleActionListItem> = ({
  leftIcon,
  text,
  open,
  onOpenChange,
  disabled,
  children
}) => {
  const handleOpenChange = useCallback((o: boolean) => {
    onOpenChange?.(o);
  }, [onOpenChange]);
  return <Collapsible disabled={disabled} open={open} onOpenChange={handleOpenChange} data-sentry-element="Collapsible" data-sentry-component="CollapsibleActionListItem" data-sentry-source-file="CollapsibleActionListItem.tsx">
      <CollapsibleTrigger asChild data-sentry-element="CollapsibleTrigger" data-sentry-source-file="CollapsibleActionListItem.tsx">
        <div className={cn("flex cursor-pointer items-center justify-between border", open && "border-b border-b-accent")}>
          <div className="flex items-center space-x-2">
            <div className="shrink-0 bg-accent p-2 text-brand">{leftIcon}</div>
            <span className="font-sans text-xs font-normal text-ink">{text}</span>
          </div>
          <div className="mr-2 shrink-0 text-accent-foreground">
            {open ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent data-sentry-element="CollapsibleContent" data-sentry-source-file="CollapsibleActionListItem.tsx">{children}</CollapsibleContent>
    </Collapsible>;
};