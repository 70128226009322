"use client";

import * as React from "react";
import { cn } from "../../lib/utils";
import { useScrollAreaContext } from "./scroll-area/scroll-area-provider";
interface IPanelHeaderProps {
  children: React.ReactNode;
}
export const PanelHeader: React.FC<IPanelHeaderProps> = ({
  children
}) => {
  const {
    scrollState
  } = useScrollAreaContext();
  return <div className={cn("sticky top-0 z-50 bg-paper", scrollState !== "start" && "shadow")} data-sentry-component="PanelHeader" data-sentry-source-file="panel-header.tsx">{children}</div>;
};