// eslint-disable-next-line no-restricted-imports -- util function wrapping lodash with more specific type
import { map as _map } from "lodash";

import type { IMatchesNeverArrayRecord } from "./types";

/**
 * Creates an array of values by running each property of `obj` through `iteratee`.
 *
 * @param obj The object to iterate over
 * @param iteratee The function invoked per iteration
 * @returns An array of the results of running `iteratee` for each property
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- generic util type
export function map<const Obj extends IMatchesNeverArrayRecord<string | number | symbol, any>, R>(
  obj: Obj | undefined,
  iteratee: (value: Obj[keyof Obj], key: keyof Obj, obj: Obj) => R,
): R[] {
  return _map(obj, iteratee);
}
