import { forEach } from "./forEach";

/**
 * Maps both keys and values of an object using an iteratee function.
 *
 * @param obj The source object
 * @param iteratee Function that returns a new key-value pair for each property
 * @returns A new object with transformed keys and values
 */
// mapKey: (value: Value, key: InKey) => OutKeys | undefined,
export function mapKeysAndValues<
  const InKeys extends string,
  const InValue,
  const OutKeys extends string,
  const OutValue,
>(
  obj: Record<InKeys, InValue>,
  /**
   * If returning undefined, the key-vale pair will not be added to the output object.
   */
  iteratee: (
    value: InValue,
    key: InKeys,
  ) =>
    | {
        key: OutKeys;
        value: OutValue;
      }
    | undefined,
): Record<OutKeys, OutValue> {
  const out = {} as Record<OutKeys, OutValue>;

  forEach(obj, (value, key) => {
    const mappedVals = iteratee(value, key);

    if (mappedVals != null) {
      out[mappedVals.key] = mappedVals.value;
    }
  });

  return out;
}
