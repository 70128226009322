import { formatNumber } from "@archetype/formatting";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cva, type VariantProps } from "class-variance-authority";
import { sum, sumBy, uniq } from "lodash";
import * as React from "react";
import { useMemoDeepCompare } from "../../hooks/useMemoDeepCompare";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
const segmentedProgressVariants = cva("h-4 rounded-sm transition-all", {
  variants: {
    color: {
      lilac: "bg-pastel-lilac-foreground",
      purple: "bg-pastel-purple-foreground",
      pink: "bg-pastel-pink-foreground",
      red: "bg-pastel-red-foreground",
      brown: "bg-pastel-brown-foreground",
      orange: "bg-pastel-orange-foreground",
      yellow: "bg-pastel-yellow-foreground",
      lime: "bg-pastel-lime-foreground",
      sage: "bg-pastel-sage-foreground",
      green: "bg-pastel-green-foreground",
      emerald: "bg-pastel-emerald-foreground",
      teal: "bg-pastel-teal-foreground",
      blue: "bg-pastel-blue-foreground",
      primary: "bg-primary",
      secondary: "bg-secondary",
      dark: "bg-ink",
      neutral: "bg-pastel-neutral-foreground"
    }
  },
  defaultVariants: {
    color: "primary"
  }
});
type ISegmentedProgressVariantColors = VariantProps<typeof segmentedProgressVariants>["color"];
type IProgressSegment = {
  value: number;
  label?: string;
  color?: ISegmentedProgressVariantColors;
  onClick?: () => void;
};
type ISegmentedProgress = React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
  segments: IProgressSegment[];
};
type IProgressSegmentWithPercentage = IProgressSegment & {
  percentage: number;
};
const makeSegmentsWithStandardPercentages = (rawSegments: IProgressSegment[]): IProgressSegmentWithPercentage[] => {
  // Only positive values
  const segments = rawSegments.map(segment => ({
    ...segment,
    value: Math.max(segment.value, 0)
  }));
  const totalValue = sumBy(segments, segment => segment.value);
  const numberOfSegments = segments.length;
  if (totalValue === 0) {
    if (numberOfSegments === 0) {
      return [];
    }
    return segments.map((segment, index) => ({
      ...segment,
      value: rawSegments[index]?.value ?? segment.value,
      // Balanced list if all segments are 0
      percentage: 100 / numberOfSegments
    }));
  }
  const scaleFactor = 100 / totalValue;
  return segments.map((segment, index) => ({
    ...segment,
    value: rawSegments[index]?.value ?? segment.value,
    // Re-add negative values if any
    percentage: segment.value * scaleFactor
  }));
};
function centerOfBellCurveEstimate(values: number[]): {
  center: number;
  range: number;
} {
  if (values.length === 0) {
    return {
      center: 0,
      range: 0
    };
  }
  const sortedValues = [...values].sort((a, b) => a - b);
  const quarter = Math.floor(sortedValues.length / 4);
  const threeQuarters = Math.floor(sortedValues.length * 3 / 4);
  const center = sortedValues.slice(quarter, threeQuarters + 1);
  if (center.length === 0) {
    return {
      center: 0,
      range: 0
    };
  }
  return {
    center: sum(center) / center.length,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- actually safe with logic
    range: sortedValues[threeQuarters]! - sortedValues[quarter]!
  };
}
const makeSegmentsWithPercentages = (rawSegments: IProgressSegment[]): IProgressSegmentWithPercentage[] => {
  // Only positive values
  const segments = rawSegments.map(segment => ({
    ...segment,
    value: Math.max(segment.value, 0)
  }));
  const totalValue = sumBy(segments, segment => segment.value);
  const nbPositiveSegments = segments.filter(segment => segment.value > 0).length;
  if (totalValue === 0) {
    const nbSegments = segments.length;
    if (segments.length === 0) {
      return [];
    }
    return segments.map((segment, index) => ({
      ...segment,
      value: rawSegments[index]?.value ?? segment.value,
      // Balanced list if all segments are 0
      percentage: 100 / nbSegments
    }));
  }

  // min is half of the size if all were equal
  const minPercentage = 100 / nbPositiveSegments / 2;
  const remainingPercentage = 100 - minPercentage * nbPositiveSegments;

  // Ignore outliers with a bell curve estimate of the average of the center half of the array
  // and use it to cap the values of the array
  // Calculate the default percentages based on raw value (positive only)
  // Use a minimum that depends on the number of segments
  // and then distribute the remaining percentage based on the standard percentages (with capped values)

  const {
    center,
    range
  } = centerOfBellCurveEstimate(segments.map(segment => segment.value).filter(value => value > 0));
  const maxValue = center + range;
  const cappedSegments = segments.map(segment => ({
    ...segment,
    value: Math.min(maxValue, segment.value)
  }));
  const standardPercentages = makeSegmentsWithStandardPercentages(cappedSegments);
  const adjustedPercentages = standardPercentages.map((segment, index) => ({
    ...segment,
    value: rawSegments[index]?.value ?? segment.value,
    // Re-add initial value
    percentage: segment.value > 0 ? Math.floor(minPercentage + remainingPercentage * segment.percentage / 100) : 0
  }));
  return adjustedPercentages;
};
const SegmentedProgressLabel: React.FC<{
  className?: string;
  label: string | undefined;
}> = ({
  className,
  label
}) => {
  const containerRef = React.useRef<HTMLSpanElement>(null);
  const innerRef = React.useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = React.useState(false);
  const truncate = React.useCallback((): void => {
    if (containerRef.current == null || innerRef.current == null) {
      return;
    }
    const containerRect = containerRef.current.getBoundingClientRect();
    const innerRect = innerRef.current.getBoundingClientRect();
    setIsTruncated(innerRect.width > containerRect.width);
  }, [setIsTruncated]);

  // Set up a resize observer
  React.useLayoutEffect(() => {
    const el = containerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const _ of entries) {
        truncate();
      }
    });
    if (el) {
      resizeObserver.observe(el);
    }
    return (): void => {
      if (el) {
        resizeObserver.unobserve(el);
      }
    };
  }, [truncate]);
  if (label == null) {
    return null;
  }
  return <span ref={containerRef} className={cn(className)} data-sentry-component="SegmentedProgressLabel" data-sentry-source-file="segmented-progress.tsx">
      <span ref={innerRef} className={cn("w-fit", {
      "opacity-0": isTruncated
    }, "group-hover:opacity-100")}>
        {label}
      </span>
    </span>;
};
const SegmentedProgress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ISegmentedProgress>(({
  className,
  segments,
  ...props
}, ref) => {
  const segmentsWithPercentages = makeSegmentsWithPercentages(segments);
  const uniqueLabels = useMemoDeepCompare(() => uniq(segmentsWithPercentages.map(({
    label
  }) => label)).length === segmentsWithPercentages.length, [segmentsWithPercentages]);
  return <ProgressPrimitive.Root ref={ref} className={cn("flex flex-row items-center gap-0.5", className)} {...props}>
        {segmentsWithPercentages.map(({
      label,
      percentage,
      value,
      color,
      onClick: handleClick
    }, index) => <div key={uniqueLabels ? label : index} className={cn("group relative flex w-0 cursor-default flex-col overflow-hidden transition-all", percentage === 0 ? "w-4 shrink-0 grow-0" : undefined, "hover:w-min hover:grow-[3]",
    // grow-[3] allows the empty dot to expand a little more to show with the scaling
    {
      "cursor-pointer": handleClick != null
    })} style={{
      flexGrow: percentage === 0 ? undefined : percentage,
      flexShrink: percentage === 0 ? undefined : percentage
    }} onClick={handleClick}>
            <span className={cn("z-10 -ml-1 mr-1 flex origin-bottom-left items-baseline space-x-1 overflow-hidden whitespace-nowrap p-1 pb-0 text-base transition-all group-hover:scale-[1.15]", percentage === 0 ? "ml-[-3px]" : undefined)}>
              <span className="shrink-0 text-xl font-bold">{formatNumber({
            value: value,
            type: "integer"
          })}</span>
              <SegmentedProgressLabel className="w-0 shrink grow group-hover:w-fit group-hover:shrink-0 group-hover:grow-0" label={label} />
            </span>
            <div className="flex h-5 w-full items-center">
              <ProgressPrimitive.Indicator className={cn(segmentedProgressVariants({
          color: color
        }), "w-full group-hover:h-5")} />
            </div>
          </div>)}
      </ProgressPrimitive.Root>;
});
SegmentedProgress.displayName = "SegmentedProgress";
const SKELETON_SEGMENTS = [3, 4, 2];
const SegmentedProgressSkeleton: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  return <div className={cn("flex w-full flex-row items-center gap-px", className)} data-sentry-component="SegmentedProgressSkeleton" data-sentry-source-file="segmented-progress.tsx">
      {SKELETON_SEGMENTS.map((segmentPercentage, index) => <div
    // eslint-disable-next-line react/no-array-index-key -- actually index based content for skeleton
    key={index} className="group relative flex w-0 flex-col space-y-1 overflow-hidden transition-all" style={{
      flexGrow: segmentPercentage,
      flexShrink: segmentPercentage
    }}>
          <span className="z-10 -ml-1 mr-1 flex items-end space-x-1 overflow-hidden whitespace-nowrap p-1">
            <Skeleton className="size-5 shrink-0" />
            <Skeleton className="h-4 w-20 shrink" />
          </span>
          <div className="flex h-5 w-full items-center">
            <Skeleton className="h-4 w-full rounded-sm" />
          </div>
        </div>)}
    </div>;
};
export { type IProgressSegment, type ISegmentedProgress, type ISegmentedProgressVariantColors, SegmentedProgress, SegmentedProgressSkeleton };