import Link from "next/link";
import type { PropsWithChildren } from "react";
import React from "react";
import type { ILinkRenderer, IRoute } from "./api";
export function simpleLinkRenderer<T extends IRoute>(hrefOrGenerator: T | (() => T)): ILinkRenderer {
  const getHref = typeof hrefOrGenerator === "function" ? hrefOrGenerator : (): T => hrefOrGenerator;
  const LinkRenderer: ILinkRenderer = (props: PropsWithChildren & {
    className?: string;
  }) => <Link className={props.className} href={getHref()} passHref={true} data-sentry-element="Link" data-sentry-component="LinkRenderer" data-sentry-source-file="simpleLinkRenderer.tsx">
      {props.children}
    </Link>;
  return LinkRenderer;
}