import { forEach } from "./forEach";

/**
 * Maps the keys of the object with the function provided, discarding any values where the function returns `undefined`
 */
export function mapKeysNoUndefined<const InKey extends string, Value, const OutKeys extends string>(
  object: Record<InKey, Value>,
  mapKey: (value: Value, key: InKey) => OutKeys | undefined,
): Record<OutKeys, Value> {
  const res = {} as Record<OutKeys, Value>;

  forEach(object, (value, key) => {
    const newKey = mapKey(value, key);

    if (newKey == null) {
      return;
    }
    res[newKey] = value;
  });

  return res;
}
