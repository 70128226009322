import Link from "next/link";
import type { IUntypedRoute } from "../../api/untyped-route";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Icon, type IIconNames } from "./icon";
type INonIdealStateVariant = "notFound" | "permissionDenied" | "error" | "accessRevoke";
type IConfigType = {
  icon: IIconNames;
  message: string;
};
const VARIANT_CONFIGS: Record<INonIdealStateVariant, IConfigType> = {
  notFound: {
    icon: "search-x",
    message: "No results found"
  },
  permissionDenied: {
    icon: "shield-x",
    message: "You don't have access to this"
  },
  error: {
    icon: "frown",
    message: "Something went wrong"
  },
  accessRevoke: {
    icon: "shield-x",
    message: "You no longer have access to this"
  }
} as const;
interface INonIdealStateProps {
  backRoute?: IUntypedRoute;
  className?: string;
  variant?: INonIdealStateVariant;
  resourceType?: string;
  icon?: IIconNames;
  message?: string;
}
export function NonIdealState({
  backRoute,
  className,
  variant,
  resourceType,
  icon,
  message: customMessage
}: INonIdealStateProps): JSX.Element {
  const config = variant != null ? {
    ...VARIANT_CONFIGS[variant],
    message: (variant === "permissionDenied" || variant === "accessRevoke") && resourceType != null && resourceType.trim() !== "" ? `${VARIANT_CONFIGS[variant].message} ${resourceType.toLowerCase()}` : VARIANT_CONFIGS[variant].message
  } : {
    icon: icon ?? "frown",
    message: customMessage ?? "Something went wrong"
  };
  return <div className={cn("flex w-full flex-col items-center justify-center gap-4 rounded border border-border bg-accent-background p-8 text-center text-muted-foreground", className)} data-sentry-component="NonIdealState" data-sentry-source-file="non-ideal-state.tsx">
      <Icon className="size-8 stroke-1" name={config.icon} data-sentry-element="Icon" data-sentry-source-file="non-ideal-state.tsx" />
      <p className="text-lg">{config.message}</p>
      {backRoute != null && <Link href={backRoute}>
          <Button className="gap-2 text-muted-foreground hover:text-muted-foreground/80" size="sm" variant="ghost">
            Go back
          </Button>
        </Link>}
    </div>;
}