import type * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import type { ControllerProps, FieldError, FieldPath, FieldValues } from "react-hook-form";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { cn } from "../../lib/utils";
import type { labelVariants } from "./label";
import { Label } from "./label";
const Form = FormProvider;
type IFormFieldContextValue<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
  name: TName;
};
const FormFieldContext = React.createContext<IFormFieldContextValue>({} as IFormFieldContextValue);
const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  ...props
}: ControllerProps<TFieldValues, TName>): JSX.Element => {
  return <FormFieldContext.Provider value={{
    name: props.name
  }} data-sentry-element="unknown" data-sentry-component="FormField" data-sentry-source-file="form.tsx">
      <Controller {...props} data-sentry-element="Controller" data-sentry-source-file="form.tsx" />
    </FormFieldContext.Provider>;
};
const useFormField = (): {
  id: string;
  name: string;
  formItemId: string;
  formDescriptionId: string;
  formMessageId: string;
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  error?: FieldError | undefined;
} => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const {
    getFieldState,
    formState
  } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);
  const {
    id
  } = itemContext;
  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};
type IFormItemContextValue = {
  id: string;
};
const FormItemContext = React.createContext<IFormItemContextValue>({} as IFormItemContextValue);
const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({
  className,
  ...props
}, ref) => {
  const id = React.useId();
  return <FormItemContext.Provider value={{
    id
  }}>
        <div ref={ref} className={cn("grow space-y-2", className)} {...props} />
      </FormItemContext.Provider>;
});
FormItem.displayName = "FormItem";
const FormLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>>(({
  className,
  ...props
}, ref) => {
  const {
    error,
    formItemId
  } = useFormField();
  return <Label ref={ref} className={cn(error && "text-error", className)} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = "FormLabel";
const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(({
  ...props
}, ref) => {
  const {
    error,
    formItemId,
    formDescriptionId,
    formMessageId
  } = useFormField();
  return <Slot ref={ref} aria-describedby={!error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`} aria-invalid={Boolean(error)} id={formItemId} {...props} />;
});
FormControl.displayName = "FormControl";
const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  ...props
}, ref) => {
  const {
    formDescriptionId
  } = useFormField();
  return <p ref={ref} className={cn("text-sm text-muted-foreground", className)} id={formDescriptionId} {...props} />;
});
FormDescription.displayName = "FormDescription";
const FormMessage = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  children,
  ...props
}, ref) => {
  const {
    error,
    formMessageId
  } = useFormField();
  const body = error ? String(error.message) : children;
  if (body == null) {
    return null;
  }
  return <p ref={ref} className={cn("text-sm font-medium text-error", className)} id={formMessageId} {...props}>
        {body}
      </p>;
});
FormMessage.displayName = "FormMessage";
export { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, useFormField };