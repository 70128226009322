"use client";

import { Command as CommandPrimitive } from "cmdk";
import { isEqual } from "lodash";
import * as React from "react";
import { usePrevious } from "react-use";
import { mergeRefs } from "../../lib/mergeRefs";
import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "./command";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Spinner } from "./spinner";
export interface IComboboxOption<T extends string = string> {
  value: T;
  label: string;
  disabled?: boolean;
}
interface ICombobox<T extends string = string> {
  value?: IComboboxOption<T> | null;
  onChange?: (option: IComboboxOption<T> | null) => void;
  options: IComboboxOption<T>[];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  triggerClassName?: string;
  isLoading?: boolean;
  onSearch?: (value: string) => void;
  onCreateItem?: (value: string) => void;
  small?: boolean;
  loadingIndicator?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}
function Combobox<T extends string = string>({
  className,
  value,
  onChange,
  options,
  placeholder,
  disabled,
  triggerClassName,
  isLoading,
  onSearch,
  onCreateItem,
  small,
  loadingIndicator,
  ref
}: ICombobox<T>): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const previousValue = usePrevious(value);
  React.useEffect(() => {
    if (value != null && !isEqual(value, previousValue)) {
      setInputValue(value.label);
    }
  }, [value, previousValue]);
  const handleSelect = React.useCallback((option: IComboboxOption<T>) => (): void => {
    onChange?.(option);
    setInputValue(option.label);
    setOpen(false);
  }, [onChange]);
  const handleInputChange = React.useCallback((newValue: string) => {
    setInputValue(newValue);
    setOpen(true);
    onSearch?.(newValue);
  }, [onSearch]);
  const handleClear = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    onChange?.(null);
    setInputValue("");
  }, [onChange]);
  const handleCreateItem = React.useCallback(() => {
    onCreateItem?.(inputValue);
    setOpen(false);
  }, [inputValue, onCreateItem]);
  const handleClickOutside = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) && inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  }, []);
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [handleClickOutside]);
  const creatable = onCreateItem != null;
  const handleTriggerClick = React.useCallback(() => {
    if (disabled == null || !disabled) {
      setOpen(true);
    }
  }, [disabled]);
  const handleInputFocus = React.useCallback(() => {
    setOpen(true);
  }, []);

  // Search and filter dont interact well in Command
  const shouldFilter = onSearch == null;
  return <Command ref={dropdownRef} className={cn("h-auto overflow-visible bg-transparent", className)} shouldFilter={shouldFilter} data-sentry-element="Command" data-sentry-component="Combobox" data-sentry-source-file="combobox.tsx">
      <Popover open={open} data-sentry-element="Popover" data-sentry-source-file="combobox.tsx">
        <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="combobox.tsx">
          <div className={cn(inputVariants({
          size: small === true ? "small" : "default"
        }), triggerClassName, "flex items-center")} onClick={handleTriggerClick}>
            <CommandPrimitive.Input ref={ref == null ? inputRef : mergeRefs(ref, inputRef)} className={cn("min-w-0 flex-1 bg-transparent outline-none placeholder:text-muted-foreground", inputValue === "" && "text-muted-foreground")} disabled={disabled} placeholder={placeholder} value={inputValue} onFocus={handleInputFocus} onValueChange={handleInputChange} data-sentry-element="unknown" data-sentry-source-file="combobox.tsx" />
            <span className="ml-2 flex shrink-0 items-center gap-x-1">
              {value ? <Icon className="cursor-pointer text-muted-foreground" name="x" role="button" onClick={handleClear} /> : null}
              {isLoading === true ? <Spinner /> : <Icon className="shrink-0 opacity-50" name="chevron-down" />}
            </span>
          </div>
        </PopoverTrigger>
        <PopoverContent align="start" className="max-h-[300px] w-full min-w-72 p-0 shadow outline-none animate-in" style={{
        width: "var(--radix-popover-trigger-width)"
      }} data-sentry-element="PopoverContent" data-sentry-source-file="combobox.tsx">
          <CommandList className="w-full" data-sentry-element="CommandList" data-sentry-source-file="combobox.tsx">
            {isLoading === true ? <div className="flex items-center justify-center p-4">
                {loadingIndicator != null ? loadingIndicator : <Loader />}
              </div> : <>
                <CommandEmpty>No options found</CommandEmpty>
                {creatable ? <CommandGroup>
                    <CommandItem className="cursor-pointer" value="create-new" onSelect={handleCreateItem}>
                      <span className="flex items-center gap-x-1">
                        <Icon name="plus" />
                        <span>Create new</span>
                      </span>
                    </CommandItem>
                  </CommandGroup> : null}
                <CommandGroup>
                  {options.map(option => <CommandItem key={String(option.value)} className="cursor-pointer" onSelect={handleSelect(option)}>
                      <Icon className={cn("mr-2 size-4", option.value === value?.value ? "opacity-100" : "opacity-0")} name="check" />
                      {option.label}
                    </CommandItem>)}
                </CommandGroup>
              </>}
          </CommandList>
        </PopoverContent>
      </Popover>
    </Command>;
}
export { Combobox };