"use client";

import * as React from "react";
import { cn } from "../../lib/utils";
import { useScrollAreaContext } from "./scroll-area/scroll-area-provider";
interface IPanelFooterProps {
  className?: string;
  children: React.ReactNode;
}
export const PanelFooter: React.FC<IPanelFooterProps> = ({
  className,
  children
}) => {
  const {
    scrollState
  } = useScrollAreaContext();
  return <div className={cn("border-t bg-muted-background p-4 transition-shadow", scrollState !== "end" ? "shadow-[0px_-2px_8px_0px_hsl(var(--gray-300))]" : "", className)} data-sentry-component="PanelFooter" data-sentry-source-file="panel-footer.tsx">
      {children}
    </div>;
};