import React from "react";
import { cn } from "../../../lib/utils";
import { Skeleton } from "../../atoms/skeleton";
type ISkeletonCommentData = {
  type: "comment";
  id: string;
  name: number;
  content: number;
} | {
  type: "event";
  id: string;
};
const SKELETON_COMMENT_DATA: Array<ISkeletonCommentData> = [{
  type: "comment",
  id: "first_comment",
  name: 4,
  content: 16
}, {
  type: "event",
  id: "event"
}, {
  type: "comment",
  id: "second_comment",
  name: 8,
  content: 30
}];
export const CommentThreadSkeleton: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  return <div className={cn(className, "max-w-full items-stretch overflow-hidden rounded border border-border bg-paper")} data-sentry-component="CommentThreadSkeleton" data-sentry-source-file="comment-thread-skeleton.tsx">
      <div>
        <div className="border-b border-accent px-3 py-4 text-base">Updates</div>
        <div className="mt-1 p-4">
          {SKELETON_COMMENT_DATA.map(skeletonData => <CommentSkeleton key={skeletonData.id} skeletonData={skeletonData} />)}
        </div>
      </div>
    </div>;
};
const CommentSkeleton: React.FC<{
  skeletonData: ISkeletonCommentData;
}> = ({
  skeletonData
}) => {
  if (skeletonData.type === "comment") {
    const nameWidth = `w-[${skeletonData.name}rem]`;
    const contentWidth = `w-[${skeletonData.content}rem]`;
    return <div key={skeletonData.id} className="flex py-2 align-top">
        <Skeleton className="mr-2 size-6 shrink-0 overflow-hidden rounded-md" />
        <div className="grow">
          <div className="flex items-center justify-start text-base">
            <Skeleton className={cn("mr-1 h-3", nameWidth)} style={{
            width: `${skeletonData.name}rem`
          }} />
            <span className="mr-1 font-bold">·</span>
            <Skeleton className="text-xs">4:20PM</Skeleton>
          </div>
          <Skeleton className={cn("mt-1 h-4", contentWidth)} style={{
          width: `${skeletonData.content}rem`
        }} />
        </div>
      </div>;
  }
  return <div className="flex items-center space-x-1" data-sentry-component="CommentSkeleton" data-sentry-source-file="comment-thread-skeleton.tsx">
      <Skeleton className="h-4 w-16" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
      <span className="font-bold">·</span>
      <Skeleton className="h-4 w-24" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
      <span className="font-bold">·</span>
      <Skeleton className="h-4 w-10" data-sentry-element="Skeleton" data-sentry-source-file="comment-thread-skeleton.tsx" />
    </div>;
};